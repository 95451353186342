define("ember-svg-jar/inlined/navigation/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.402 5H4.68c-.646 0-1.265.26-1.722.722a2.48 2.48 0 00-.714 1.742v4.106a.83.83 0 00.239.58c.152.153.357.24.572.24h1.071v13.967a1.657 1.657 0 00.475 1.162A1.62 1.62 0 005.75 28h19.503a1.606 1.606 0 001.15-.48 1.641 1.641 0 00.475-1.163V12.393h1.154a.811.811 0 00.572-.242.83.83 0 00.24-.578v-4.11a2.49 2.49 0 00-.717-1.742A2.437 2.437 0 0026.402 5zM25 26H6V12.393h19V26zm1.843-15.5h-22.6V7h22.6v3.5zm-13.985 6.616h5.27a.608.608 0 00.43-.181.621.621 0 00.18-.435v-1a.621.621 0 00-.18-.435.608.608 0 00-.43-.18h-5.286a.608.608 0 00-.43.18.621.621 0 00-.179.435v1a.624.624 0 00.388.573c.075.03.156.045.237.043z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});