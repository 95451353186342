define("ember-svg-jar/inlined/file-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M14.977 11.41a3.136 3.136 0 11-6.272 0 3.136 3.136 0 016.272 0zm-1.254 0a1.882 1.882 0 11-3.764 0 1.882 1.882 0 013.764 0z\"/><path d=\"M18.439 2.767l7.794 7.794c.511.511.767 1.15.767 1.789v15.206a2.563 2.563 0 01-2.556 2.555H6.556A2.563 2.563 0 014 27.556v-23A2.563 2.563 0 016.556 2h10.222c.639 0 1.278.256 1.66.767zM6.556 4.556V19.47l2.118-1.905 6.105 5.11 6.44-6.04 3.225 3.25v-7.664l-7.666-7.667H6.556zm0 23v-5.762l2.118-1.905L14.779 25l6.44-5.97 3.225 3.188v5.338H6.556z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "#F7D154"
    }
  };
});