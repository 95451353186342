define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.119 8.881l-2.881 2.882a.75.75 0 01-1.063 0l-6.938-6.938a.75.75 0 010-1.062L23.12.88a3.007 3.007 0 014.244 0l3.756 3.757a2.995 2.995 0 010 4.243zM17.762 6.238L1.35 22.65.025 30.244a1.502 1.502 0 001.737 1.737l7.594-1.33L25.77 14.237a.75.75 0 000-1.063l-6.94-6.936a.758.758 0 00-1.069 0zM7.756 21.244a.871.871 0 010-1.238l9.625-9.625a.871.871 0 011.238 0 .871.871 0 010 1.238l-9.625 9.625a.871.871 0 01-1.238 0zM5.5 26.5h3v2.269l-4.031.706-1.944-1.944.706-4.03H5.5v3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});