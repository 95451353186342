define("ember-svg-jar/inlined/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.074 23.39l1.02 1.02 4.074-4.075 5.093 4.074 4.074-5.093-1.018-1.018-3.056 4.074-5.093-4.074-5.094 5.093zM12.797 14.022H6.686v1.679h6.111v-1.679z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M24.41 17.413a7.875 7.875 0 10-6.396-14.256H3.745C2.782 3.157 2 3.94 2 4.903v23.351C2 29.218 2.782 30 3.746 30h18.918c.964 0 1.746-.782 1.746-1.746v-10.84zm-1.746.319v10.591H3.746V4.836h12.327a7.853 7.853 0 00-1.55 2.978H7.237v1.679h7.021a8.068 8.068 0 00.06 1.424H7.057v1.679h7.675a7.878 7.878 0 007.93 5.136zm-.661-12.205a.31.31 0 00-.102.073l.001-.026-.472.499a.305.305 0 00-.1.223.237.237 0 00.1.198l2.808 2.737h-6.189a.287.287 0 00-.212.085.29.29 0 00-.087.213v.696a.288.288 0 00.087.21.288.288 0 00.211.088h6.19l-2.809 2.732a.238.238 0 00-.1.2.307.307 0 00.1.223l.473.472a.308.308 0 00.223.1.236.236 0 00.2-.1l4.1-4.074a.272.272 0 00.075-.2.364.364 0 00-.074-.223l-4.102-4.079a.271.271 0 00-.199-.074.309.309 0 00-.122.027z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});