define("ember-svg-jar/inlined/file-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#48A469\" fill-rule=\"evenodd\"><path d=\"M12.706 23.566h8.265a.514.514 0 00.516-.515V12.737a.516.516 0 00-.516-.514H9.627a.514.514 0 00-.516.514v10.315a.516.516 0 00.516.514h3.079zm.53-1.03h3.094v-1.031h-3.094v1.03zm0-2.063h3.094v-1.031h-3.094v1.031zm4.126 2.062v-1.03h3.094v1.03h-3.094zm0-2.062h3.094v-1.031h-3.094v1.031zm3.094-3.094v1.032h-3.094V17.38h3.094zm-7.22 1.032h3.094V17.38h-3.094v1.032zm0-2.063h3.094v-1.031h-3.094v1.03zm7.22 0h-3.094v-1.031h3.094v1.03zm0-2.063h-3.094v-1.032h3.094v1.032zm-7.22 0h3.094v-1.032h-3.094v1.032zm-1.03 8.25v-1.03h-2.064v1.03h2.063zm0-3.093v1.031h-2.064v-1.031h2.063zm0-1.03h-2.064v-1.033h2.063v1.032zm0-2.064h-2.064v-1.031h2.063v1.03zm0-2.063h-2.064v-1.032h2.063v1.032z\"/><path d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H6.556z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});