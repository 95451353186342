define("ember-svg-jar/inlined/activity-icon-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm8.2-23H7.4a1.4 1.4 0 00-1.353 1.04L15 16c.167.167.6.5 1 .5s.833-.333 1-.5l8.506-6.104A1.399 1.399 0 0024.2 9zM6 21.6v-9.59L15 18c.167.167.6.5 1 .5s.833-.333 1-.5l8.6-6.171V21.6a1.4 1.4 0 01-1.4 1.4H7.4A1.4 1.4 0 016 21.6z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});