define("ember-svg-jar/inlined/one-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.753 1.5h21.042c.969 0 1.753.785 1.753 1.753v25.494c0 .968-.784 1.753-1.753 1.753H5.753A1.753 1.753 0 014 28.747V3.253C4 2.285 4.785 1.5 5.753 1.5zm21.043 1.686H5.753v25.63h21.043V3.187z\"/><path d=\"M10.046 18.103L9 17.003l5.227-5.503 5.227 4.402 3.137-4.402 1.045 1.1-4.181 5.503-5.228-4.402-4.181 4.402z\"/><circle cx=\"11\" cy=\"7.5\" r=\"2\"/><path d=\"M9 20.5h15v2H9zM9 24.5h15v2H9z\"/>",
    "attrs": {
      "width": "32",
      "height": "33",
      "viewBox": "0 0 32 33",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});