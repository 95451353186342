define("ember-svg-jar/inlined/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 2H2v26a2 2 0 002 2h26v-2H4V2z\"/><path d=\"M30 9h-7v2h3.59L19 18.59l-4.29-4.3a1.002 1.002 0 00-1.42 0L6 21.59 7.41 23 14 16.41l4.29 4.3a1.002 1.002 0 001.42 0l8.29-8.3V16h2V9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});