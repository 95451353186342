define("ember-svg-jar/inlined/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.333 3.956H10.667C4.776 3.956 0 8.73 0 14.622S4.776 25.29 10.667 25.29h10.666C27.224 25.289 32 20.513 32 14.622c0-5.89-4.776-10.666-10.667-10.666zM3.556 14.622c0-3.93 3.18-7.11 7.11-7.11s7.112 3.18 7.112 7.11-3.18 7.111-7.111 7.111a7.107 7.107 0 01-7.111-7.11zm17.777 7.111h-2.717a10.662 10.662 0 000-14.222h2.717c3.93 0 7.111 3.18 7.111 7.111 0 3.93-3.18 7.111-7.11 7.111z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});