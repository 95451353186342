define("ember-svg-jar/inlined/chart-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 28V6h-8v22h-4V14H7v14H4V2H2v26a2 2 0 002 2h26v-2h-3zm-14 0H9V16h4v12zm12 0h-4V8h4v20z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});