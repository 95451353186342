define("ember-light-table/components/lt-scaffolding-row", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.columns as |column|}}
    {{! template-lint-disable no-inline-styles }}
    <td style={{html-safe (if column.width (concat "width: " column.width))}} class="lt-scaffolding"></td>
    {{! template-lint-enable no-inline-styles }}
  {{/each}}
  */
  {
    "id": "gDCzwuZh",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"columns\"]]],null]],null],null,[[[1,\"  \"],[10,\"td\"],[15,5,[28,[37,2],[[52,[30,1,[\"width\"]],[28,[37,4],[\"width: \",[30,1,[\"width\"]]],null]]],null]],[14,0,\"lt-scaffolding\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"column\"],false,[\"each\",\"-track-array\",\"html-safe\",\"if\",\"concat\"]]",
    "moduleName": "ember-light-table/components/lt-scaffolding-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNames: ['lt-scaffolding-row'],
    tagName: 'tr'
  }));
});