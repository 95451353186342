define("ember-svg-jar/inlined/request-recurring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 30h6v-2H6V4h16v9h2V4a2.002 2.002 0 00-2-2H6a2.002 2.002 0 00-2 2v24a2.002 2.002 0 002 2z\"/><path d=\"M20 6H8v2h12V6zM20 10H8v2h12v-2zM8 14h5.333v2H8v-2zM12 24H8v2h4v-2zM25.559 22.82c0 .06.002-.059 0 0h-.897a4.483 4.483 0 11-8.624-1.715c.34-.819.725-1.112 1.462-1.605.737-.493 1.613-1 2.5-1h3l-1.275 1.293.045.057.629.73 2.69-2.69.337-.39-3.426-3-.701.707-.29.292L22.51 17H20a6 6 0 104.989 9.333c.66-.986 1.011-2.316 1.011-3.503l-.441-.01z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});