define("ember-svg-jar/inlined/youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.331 7.599c-.368-1.417-1.452-2.532-2.829-2.911C26.007 4 16 4 16 4S5.993 4 3.498 4.688c-1.377.379-2.461 1.494-2.83 2.91C0 10.167 0 15.524 0 15.524s0 5.357.669 7.925a4.005 4.005 0 002.829 2.864C5.993 27 16 27 16 27s10.007 0 12.502-.688a4.005 4.005 0 002.83-2.864C32 20.88 32 15.523 32 15.523s0-5.357-.669-7.924zM12.727 20.387V10.66l8.364 4.863-8.364 4.864z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});