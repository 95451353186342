define("ember-svg-jar/inlined/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 19l-4 3.2V20a2.002 2.002 0 00-2-2h-8a2.002 2.002 0 00-2 2v6a2.002 2.002 0 002 2h8a2.002 2.002 0 002-2v-2.2l4 3.2v-8zm-14 7v-6h8l.002 6H16z\"/><path d=\"M12 28H8V4h8v6a2.005 2.005 0 002 2h6v3h2v-5a.909.909 0 00-.3-.7l-7-7A.908.908 0 0018 2H8a2.006 2.006 0 00-2 2v24a2.006 2.006 0 002 2h4v-2zm6-23.6l5.6 5.6H18V4.4z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});