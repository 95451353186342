define("ember-scrollable/templates/components/scroll-content-element", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='tse-scroll-content'
    style={{this.style}}
    ...attributes
    {{did-insert this.elementInserted}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "3Pw4QdMM",
    "block": "[[[11,0],[24,0,\"tse-scroll-content\"],[16,5,[30,0,[\"style\"]]],[17,1],[4,[38,0],[[30,0,[\"elementInserted\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"yield\"]]",
    "moduleName": "ember-scrollable/templates/components/scroll-content-element.hbs",
    "isStrictMode": false
  });
});