define("ember-svg-jar/inlined/ibm/replicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 8h2V4a2.002 2.002 0 00-2-2h-4v2h4zM17 2h4v2h-4zm11 9h2v4h-2zm0 7v4h-4V10a2.002 2.002 0 00-2-2H10V4h4V2h-4a2.002 2.002 0 00-2 2v4H4a2.002 2.002 0 00-2 2v18a2.002 2.002 0 002 2h18a2.002 2.002 0 002-2v-4h4a2.002 2.002 0 002-2v-4zm-6 10H4V10h18z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});