define("ember-svg-jar/inlined/font-awesome/arrows-alt-v", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M0 0h32v32H0z\"/></clipPath></defs><g clip-path=\"url(#a)\"><path d=\"M21.26 23.621h-2.878V8.38h2.878c1.337 0 2.006-1.616 1.061-2.561L16.942.439a1.5 1.5 0 00-2.12 0l-5.38 5.379c-.945.945-.275 2.56 1.061 2.56h2.879v15.243h-2.879c-1.336 0-2.006 1.616-1.06 2.561l5.378 5.379a1.5 1.5 0 002.121 0l5.379-5.379c.945-.945.276-2.56-1.06-2.56z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});