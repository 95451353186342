define("ember-svg-jar/inlined/metric-alerts/percentage-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.104 23.105a5.334 5.334 0 10-7.544-7.543 5.334 5.334 0 007.544 7.543zm21.334 13.79a5.334 5.334 0 10-7.543 7.544 5.334 5.334 0 007.542-7.544zm-.162-19.286l-1.885-1.885a2.666 2.666 0 00-3.771 0L15.724 38.62a2.666 2.666 0 000 3.771l1.885 1.885a2.666 2.666 0 003.771 0L44.276 21.38a2.666 2.666 0 000-3.77z\" fill=\"#2B3236\"/>",
    "attrs": {
      "viewBox": "0 0 60 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});