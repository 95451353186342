define("ember-svg-jar/inlined/activity-icon-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zm-13.44 3.146h3.306a.571.571 0 00.389-.972l-8.866-9.12a.551.551 0 00-.944.402v13.395a.55.55 0 00.944.402l2.701-2.78 1.653 4.026a.552.552 0 00.727.301l1.534-.667a.579.579 0 00.294-.747l-1.739-4.24z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});