define("ember-svg-jar/inlined/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.956 4.28H4.044A4.049 4.049 0 000 8.324v15.352a4.049 4.049 0 004.044 4.044h23.912A4.049 4.049 0 0032 23.676V8.324a4.049 4.049 0 00-4.044-4.044zm-.632 2.609L16 15.636 4.676 6.89h22.648zm.632 18.222H4.044a1.437 1.437 0 01-1.435-1.435V8.588l12.594 9.729a1.302 1.302 0 001.594 0l12.594-9.729v15.088c0 .791-.644 1.435-1.435 1.435z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});