define("ember-svg-jar/inlined/usd-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 2C8.266 2 2 8.266 2 16s6.266 14 14 14 14-6.266 14-14S23.734 2 16 2zm0 26.194c-6.723 0-12.194-5.47-12.194-12.194C3.806 9.277 9.276 3.806 16 3.806c6.723 0 12.194 5.47 12.194 12.194 0 6.723-5.47 12.194-12.194 12.194zM18.275 15.7l-4.064-1.14a1.584 1.584 0 01-1.163-1.508c0-.864.722-1.57 1.608-1.57h2.54c.633 0 1.237.204 1.728.571.18.136.43.113.587-.045l.638-.65a.454.454 0 00-.045-.677 4.657 4.657 0 00-2.913-1.01h-.288V7.414a.453.453 0 00-.451-.452h-.904a.453.453 0 00-.451.452v2.258h-.44c-1.88 0-3.416 1.513-3.416 3.376 0 1.501 1.022 2.834 2.478 3.246l4.065 1.14a1.584 1.584 0 011.163 1.507c0 .864-.723 1.57-1.61 1.57h-2.54a2.882 2.882 0 01-1.727-.57.448.448 0 00-.587.044l-.638.65a.454.454 0 00.045.677 4.658 4.658 0 002.913 1.01h.294v2.259c0 .248.203.451.451.451h.904a.453.453 0 00.451-.451v-2.258h.44c1.88 0 3.416-1.513 3.416-3.376a3.403 3.403 0 00-2.484-3.246z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});