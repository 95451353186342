define("ember-svg-jar/inlined/ibm/user-certification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25 10l1.593 3 3.407.414-2.5 2.253L28 19l-3-1.875L22 19l.5-3.333-2.5-2.253L23.5 13l1.5-3zm-3 20h-2v-5a5.006 5.006 0 00-5-5H9a5.006 5.006 0 00-5 5v5H2v-5a7.008 7.008 0 017-7h6a7.008 7.008 0 017 7zM12 4a5 5 0 11-5 5 5 5 0 015-5m0-2a7 7 0 107 7 7 7 0 00-7-7z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});