define("ember-light-table/components/lt-foot", ["exports", "@ember/component", "ember-light-table/mixins/table-header", "@ember/template-factory"], function (_exports, _component, _tableHeader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberWormhole
    @to={{concat this.tableId "_inline_foot"}}
    @renderInPlace={{this.renderInPlace}}
  >
    <table class={{this.tableClassNames}}>
      <tfoot class="lt-foot">
        {{! Scaffolding is needed here to allow use of colspan in the footer }}
        <LtScaffoldingRow @columns={{this.columns}} />
        {{#if (has-block)}}
          {{yield this.columns}}
        {{else}}
          <tr>
            {{#each this.columns as |column|}}
              {{component
                (concat "light-table/columns/" column.type)
                column=column
                table=this.table
                tableActions=this.tableActions
                extra=this.extra
                sortIcons=this.sortIcons
                resizeOnDrag=this.resizeOnDrag
                click=(action "onColumnClick" column)
                doubleClick=(action "onColumnDoubleClick" column)
                onColumnResized=(action "onColumnResized")
                onColumnDrag=(action "onColumnDrag")
                onColumnDrop=(action "onColumnDrop")
              }}
            {{/each}}
          </tr>
        {{/if}}
      </tfoot>
    </table>
  </EmberWormhole>
  */
  {
    "id": "pH/z0x99",
    "block": "[[[8,[39,0],null,[[\"@to\",\"@renderInPlace\"],[[28,[37,1],[[30,0,[\"tableId\"]],\"_inline_foot\"],null],[30,0,[\"renderInPlace\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"table\"],[15,0,[30,0,[\"tableClassNames\"]]],[12],[1,\"\\n    \"],[10,\"tfoot\"],[14,0,\"lt-foot\"],[12],[1,\"\\n\"],[1,\"      \"],[8,[39,2],null,[[\"@columns\"],[[30,0,[\"columns\"]]]],null],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"        \"],[18,2,[[30,0,[\"columns\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"columns\"]]],null]],null],null,[[[1,\"            \"],[46,[28,[37,1],[\"light-table/columns/\",[30,1,[\"type\"]]],null],null,[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[30,1],[30,0,[\"table\"]],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"sortIcons\"]],[30,0,[\"resizeOnDrag\"]],[28,[37,9],[[30,0],\"onColumnClick\",[30,1]],null],[28,[37,9],[[30,0],\"onColumnDoubleClick\",[30,1]],null],[28,[37,9],[[30,0],\"onColumnResized\"],null],[28,[37,9],[[30,0],\"onColumnDrag\"],null],[28,[37,9],[[30,0],\"onColumnDrop\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"column\",\"&default\"],false,[\"ember-wormhole\",\"concat\",\"lt-scaffolding-row\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"component\",\"action\"]]",
    "moduleName": "ember-light-table/components/lt-foot.hbs",
    "isStrictMode": false
  });

  /**
   * @module Light Table
   */

  /**
   * ```hbs
   * <LightTable @table={{this.table}} as |t|>
   *   <t.foot @onColumnClick={{this.sortByColumn}} />
   * </LightTable>
   * ```
   * If you want to define your own tfoot, just declare the contextual component in a block.
   *
   * ```hbs
   * <LightTable @table={{this.table}} as |t|>
   *   <t.foot @onColumnClick={{this.sortByColumn}} as |columns table|?
   *     {{#each columns as |column|}}
   *       {{!-- ... --}}
   *     {{/each}}
   *   </t.foot>
   * </LightTable>
   * ```
   *
   * will be empty
   *
   * @class t.foot
   * @uses TableHeaderMixin
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_tableHeader.default, {
    classNames: ['lt-foot-wrap'],
    table: null,
    sharedOptions: null,
    sharedOptionsFixedKey: 'fixedFooter'
  }));
});