define("ember-cli-new-version/services/new-version", ["exports", "@ember/application", "@ember/runloop", "@ember/service", "@ember/test-waiters", "@glimmer/tracking", "ember", "ember-concurrency", "fetch"], function (_exports, _application, _runloop, _service, _testWaiters, _tracking, _ember, _emberConcurrency, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let taskRunCounter = 0;
  const ONE_MINUTE = 60000;

  /**
   * @typedef {object} Configuration
   * @property {string} versionFileName
   * @property {number} firstCheckInterval
   * @property {number} updateInterval
   * @property {boolean} enableInTests
   * @property {boolean} enableInDev
   * @property {number} maxCountInTesting
   * @property {string} currentVersion
   */
  let NewVersionService = _exports.default = (_class = class NewVersionService extends _service.default {
    get _fastboot() {
      let owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }
    get _config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    get isDev() {
      return this._config.environment === 'development';
    }

    /**
     * @type Configuration
     */
    get _newVersionConfig() {
      return this._config.newVersion;
    }

    /**
     * @type {string}
     */
    get currentVersion() {
      return this._newVersionConfig.currentVersion;
    }

    /**
     * @type {string | undefined}
     */

    /**
     * Templates can use this attribute to show or hide a proposition to reload the page.
     * This getter can be overriden to change the update strategy.
     *
     * By default, a new version is considered available when there is a difference
     * between the local version and the remote version.
     *
     * @returns {boolean} true if a new version is available.
     */
    get isNewVersionAvailable() {
      return !this.ignoredVersions.includes(this.latestVersion) && this.latestVersion && this.currentVersion != this.latestVersion;
    }
    get url() {
      const versionFileName = this._newVersionConfig.versionFileName;
      const baseUrl = this._config.prepend || this._config.rootURL || this._config.baseURL;
      return baseUrl + versionFileName;
    }
    get updateIntervalWithTesting() {
      let enableInTests = this._newVersionConfig.enableInTests;
      return !enableInTests && _ember.default.testing ? 0 : this._newVersionConfig.updateInterval;
    }
    constructor() {
      var _this$_fastboot;
      super(...arguments);
      _initializerDefineProperty(this, "latestVersion", _descriptor, this);
      _initializerDefineProperty(this, "ignoredVersions", _descriptor2, this);
      if ((_this$_fastboot = this._fastboot) !== null && _this$_fastboot !== void 0 && _this$_fastboot.isFastBoot) {
        return;
      }

      // TODO: move the testing logic to a test version of the service
      if (_ember.default.testing) {
        taskRunCounter = 0;
      }
      if ((!_ember.default.testing || this._newVersionConfig.enableInTests) && (!this.isDev || this._newVersionConfig.enableInDev)) {
        if (this._newVersionConfig.firstCheckInterval > 0) {
          (0, _runloop.later)(this, () => {
            this.updateVersion.perform();
          }, this._newVersionConfig.firstCheckInterval);
        } else {
          this.updateVersion.perform();
        }
      }
    }
    *updateVersion() {
      const url = this.url;
      try {
        yield (0, _fetch.default)(url + '?_=' + Date.now()).then(response => {
          if (!response.ok) throw new Error(response.statusText);
          return response.text();
        }).then(res => {
          this.latestVersion = res ? res.trim() : undefined;

          // Call kept for compatibility with older version of the lib
          if (this.isNewVersionAvailable) {
            this.onNewVersion(this.latestVersion, this.ignoredVersions[this.ignoredVersions.length - 1] || this.currentVersion);
          }
        });
      } catch (e) {
        this.onError(e);
      } finally {
        let updateInterval = this.updateIntervalWithTesting;
        if (updateInterval === null || updateInterval === undefined) {
          updateInterval = ONE_MINUTE;
        }
        yield (0, _emberConcurrency.timeout)(updateInterval);
        if (_ember.default.testing && ++taskRunCounter > this._newVersionConfig.maxCountInTesting) {
          return;
        }
        if (_ember.default.testing && !this._newVersionConfig.enableInTests) {
          return;
        }
        this.updateVersion.perform();
      }
    }

    /**
     * Tells NewVersionService to ignore the given version.
     * If ignored, it won't trigger set `isNewVersionAvailable` to `true`.
     * The list of ignored is kept in memory only: if the site is reloaded, the list is empty.
     * @param {string} version
     */
    ignoreVersion(version) {
      this.ignoredVersions = [...this.ignoredVersions, version];
    }

    // eslint-disable-next-line no-unused-vars
    onNewVersion(newVersion, currentVersion) {
      // Kept for compatibility with older version of the lib
    }
    onError(error) {
      if (!_ember.default.testing) {
        console.log(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "latestVersion", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ignoredVersions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateVersion", [_emberConcurrency.task, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "updateVersion"), _class.prototype)), _class);
});