define("ember-svg-jar/inlined/update-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.376 7.031H21.75v1.814H9.376V7.03zm-.185 3.352h11.846v1.814H9.19v-1.814zm.036 14.577l-1.045-1.1 5.227-5.503 5.227 4.402 3.137-4.402 1.045 1.1-4.182 5.503-5.227-4.402-4.182 4.402zm9.447-11.222H8.809v1.813h9.865v-1.813z\"/><path d=\"M25.208 2H5.792C4.802 2 4 2.845 4 3.886v25.228C4 30.155 4.802 31 5.792 31h19.416c.99 0 1.792-.845 1.792-1.886V15.55h-1.792v13.64H5.792V3.814h19.416V15.55H27V3.886C27 2.844 26.198 2 25.208 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});