define("ember-light-table/components/cells/base", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/template", "@ember/template-factory"], function (_exports, _component, _object, _computed, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.column.cellComponent}}
    {{component
      (ensure-safe-component this.column.cellComponent)
      tableActions=this.tableActions
      extra=this.extra
      table=this.table
      column=this.column
      row=this.row
      value=this.value
      rawValue=this.rawValue
    }}
  {{else}}
    {{this.value}}
  {{/if}}
  */
  {
    "id": "Eq+WXYp+",
    "block": "[[[41,[30,0,[\"column\",\"cellComponent\"]],[[[1,\"  \"],[46,[28,[37,2],[[30,0,[\"column\",\"cellComponent\"]]],null],null,[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"table\"]],[30,0,[\"column\"]],[30,0,[\"row\"]],[30,0,[\"value\"]],[30,0,[\"rawValue\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"component\",\"ensure-safe-component\"]]",
    "moduleName": "ember-light-table/components/cells/base.hbs",
    "isStrictMode": false
  });

  /**
   * @module Light Table
   * @submodule Cell Types
   */

  /**
   * @module Cell Types
   * @class Base Cell
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: 'td',
    classNames: ['lt-cell'],
    attributeBindings: ['style'],
    classNameBindings: ['align', 'isSorted', 'column.cellClassNames'],
    enableScaffolding: false,
    isSorted: (0, _computed.readOnly)('column.sorted'),
    style: (0, _object.computed)('enableScaffolding', 'column.width', function () {
      let column = this.column;
      let columnWidth = column.get('width');
      if (this.enableScaffolding || !column) {
        return;
      }

      // For performance reasons, it's more interesting to bypass cssStyleify
      // since it leads to a lot of garbage collections
      // when displaying many cells
      return columnWidth ? (0, _template.htmlSafe)(`width: ${columnWidth};`) : null;
    }),
    align: (0, _object.computed)('column.align', function () {
      return `align-${this.column.align}`;
    }),
    /**
     * @property table
     * @type {Table}
     */
    table: null,
    /**
     * @property column
     * @type {Column}
     */
    column: null,
    /**
     * @property row
     * @type {Row}
     */
    row: null,
    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * @property rawValue
     * @type {Mixed}
     */
    rawValue: null,
    /**
     * @property value
     * @type {Mixed}
     */
    value: (0, _object.computed)('column.format', 'rawValue', function () {
      let rawValue = this.rawValue;
      let format = this.column.format;
      if (format && typeof format === 'function') {
        return format.call(this, rawValue);
      }
      return rawValue;
    })
  }));
});