define("ember-svg-jar/inlined/font-awesome/info-circle-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 .5C7.44.5.5 7.443.5 16c0 8.562 6.94 15.5 15.5 15.5 8.56 0 15.5-6.938 15.5-15.5C31.5 7.443 24.56.5 16 .5zm0 6.875a2.625 2.625 0 110 5.25 2.625 2.625 0 010-5.25zm3.5 15.875a.75.75 0 01-.75.75h-5.5a.75.75 0 01-.75-.75v-1.5a.75.75 0 01.75-.75H14v-4h-.75a.75.75 0 01-.75-.75v-1.5a.75.75 0 01.75-.75h4a.75.75 0 01.75.75V21h.75a.75.75 0 01.75.75v1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});