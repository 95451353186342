define("ember-svg-jar/inlined/sendspark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.5 3C25.983 3 28 4.956 28 7.364v21.818l-9-2.91H8.5c-.825 0-1.597-.21-2.257-.588l6.584-4.968 8.063-6.08-8.063-6.08L6.244 3.59A4.526 4.526 0 018.5 3h15zM16 14.636l-4.912 3.702-6.795 5.12A4.254 4.254 0 014 21.91V7.364c0-.546.105-1.07.292-1.55l6.796 5.12L16 14.636z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});