define("ember-light-table/components/lt-scrollable", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.virtualScrollbar}}
    <EmberScrollable
      class="lt-scrollable"
      @autoHide={{this.autoHide}}
      @horizontal={{this.horizontal}}
      @vertical={{this.vertical}}
      @scrollToY={{this.scrollTo}}
      @onScrollY={{this.onScrollY}} as |scrollbar|
    >
      {{yield scrollbar}}
    </EmberScrollable>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "PNBrqs8k",
    "block": "[[[41,[30,0,[\"virtualScrollbar\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"lt-scrollable\"]],[[\"@autoHide\",\"@horizontal\",\"@vertical\",\"@scrollToY\",\"@onScrollY\"],[[30,0,[\"autoHide\"]],[30,0,[\"horizontal\"]],[30,0,[\"vertical\"]],[30,0,[\"scrollTo\"]],[30,0,[\"onScrollY\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,[[30,1]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"scrollbar\",\"&default\"],false,[\"if\",\"ember-scrollable\",\"yield\"]]",
    "moduleName": "ember-light-table/components/lt-scrollable.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    vertical: true,
    horizontal: false
  }));
});