define("ember-svg-jar/inlined/chart-types/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M18.5 9.2v6.9h10.4V9.2H18.5zm0 11.6v6.9h18.6v-6.9H18.5zm28.2 11.5H18.5v6.9h28.2v-6.9zM28.9 43.8H18.5v6.9h10.4v-6.9z\"/><path fill=\"none\" stroke=\"#aaa\" stroke-width=\"2\" stroke-miterlimit=\"10\" d=\"M14.3 9.3v41.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60",
      "class": "icon-bar-chart"
    }
  };
});