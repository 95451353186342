define("ember-svg-jar/inlined/individual-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 21h8.17l-2.58 2.59L25 25l5-5-5-5-1.41 1.41L26.17 19H18v2z\"/><path d=\"M22 14v-4a1 1 0 00-.29-.71l-7-7A1 1 0 0014 2H4a2 2 0 00-2 2v24a2 2 0 002 2h16a2 2 0 002-2v-2h-2v2H4V4h8v6a2 2 0 002 2h6v2h2zm-8-4V4.41L19.59 10H14z\"/><path d=\"M9.759 24h1.845v-3.094h1.346L14.604 24h2.037l-1.854-3.392c.993-.426 1.542-1.291 1.542-2.484 0-1.735-1.146-2.851-3.127-2.851H9.759V24zm1.845-4.577v-2.642h1.244c1.065 0 1.581.473 1.581 1.343 0 .865-.516 1.3-1.572 1.3h-1.253z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});