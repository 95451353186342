define("ember-svg-jar/inlined/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 25.5h26.796a.662.662 0 01.498.218c.136.143.21.333.206.53v1.002a.748.748 0 01-.206.53.655.655 0 01-.498.22H1.702a.657.657 0 01-.498-.218A.75.75 0 011 27.25V4.75a.74.74 0 01.206-.53.655.655 0 01.496-.22h1.096a.657.657 0 01.496.218.75.75 0 01.206.532V25.5z\"/><path d=\"M9.44 21.714a.695.695 0 01-.206.493.705.705 0 01-.497.204H7.702a.705.705 0 01-.496-.204.695.695 0 01-.206-.493v-4.181a.704.704 0 01.702-.697h1.035c.187 0 .365.074.497.204.131.131.205.308.205.493v4.181zm5.613 0a.695.695 0 01-.206.493.704.704 0 01-.496.204h-1.15a.705.705 0 01-.496-.204.695.695 0 01-.205-.493V10.077c0-.185.074-.362.205-.493a.704.704 0 01.497-.204h1.149c.186 0 .365.073.496.204.132.13.206.308.206.493v11.637zm5.613 0a.695.695 0 01-.205.493.705.705 0 01-.496.204h-1.14a.705.705 0 01-.496-.204.695.695 0 01-.205-.493v-7.911c0-.185.074-.363.205-.493a.705.705 0 01.497-.204h1.139c.186 0 .364.073.496.204.131.13.205.308.205.493v7.911zm5.614 0a.692.692 0 01-.434.644.706.706 0 01-.268.053h-1.082a.705.705 0 01-.496-.204.695.695 0 01-.205-.493V8.216c0-.185.073-.362.205-.493a.704.704 0 01.496-.204h1.082a.705.705 0 01.648.43.692.692 0 01.054.267v13.498z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});