define("ember-svg-jar/inlined/ibm/information-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 1a7 7 0 100 14A7 7 0 008 1zm0 3a.75.75 0 110 1.5A.75.75 0 018 4zm2 8.063H6v-1.126h1.438V8.063H6.5V6.937h2.063v4H10v1.126z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});