define("ember-svg-jar/inlined/letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.93 26.714h-1.567L19.605 2.452A2.143 2.143 0 0017.58 1h-3.16a2.143 2.143 0 00-2.027 1.452L3.64 26.714H2.073A1.073 1.073 0 001 27.787v2.14A1.073 1.073 0 002.073 31h8.571a1.073 1.073 0 001.07-1.073v-2.14a1.072 1.072 0 00-1.072-1.073h-1.31l1.56-4.285h10.217l1.56 4.285h-1.313a1.073 1.073 0 00-1.07 1.073v2.14A1.072 1.072 0 0021.358 31h8.572A1.072 1.072 0 0031 29.927v-2.14a1.072 1.072 0 00-1.073-1.073h.003zM12.84 17.07l3.158-8.67 3.159 8.67h-6.319.003z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});