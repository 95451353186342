define("ember-svg-jar/inlined/ibm/play-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 23a1 1 0 01-1-1V10a1 1 0 011.447-.894l12 6a1 1 0 010 1.788l-12 6A1.001 1.001 0 0111 23zm1-11.382v8.764L20.764 16z\"/><path d=\"M16 4A12 12 0 114 16 12 12 0 0116 4m0-2a14 14 0 1014 14A14 14 0 0016 2z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});