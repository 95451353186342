define("ember-svg-jar/inlined/ibm/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.288 23.292l7.997-7.997 1.415 1.414-7.998 7.997z\"/><path d=\"M17 30a1 1 0 01-.37-.07 1 1 0 01-.62-.79l-1-7 2-.28.75 5.27L21 24.52V17a1 1 0 01.29-.71l4.07-4.07A8.94 8.94 0 0028 5.86V4h-1.86a8.94 8.94 0 00-6.36 2.64l-4.07 4.07A1 1 0 0115 11H7.48l-2.61 3.26 5.27.75-.28 2-7-1a1 1 0 01-.79-.62 1 1 0 01.15-1l4-5A1 1 0 017 9h7.59l3.77-3.78A10.92 10.92 0 0126.14 2H28a2 2 0 012 2v1.86a10.92 10.92 0 01-3.22 7.78L23 17.41V25a1 1 0 01-.38.78l-5 4A1 1 0 0117 30z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});