define("ember-svg-jar/inlined/ibm/text-underline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 26H4v2h24v-2zm-12-3a7 7 0 01-7-7V5h2v11a5 5 0 1010 0V5h2v11a7 7 0 01-7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});