define("ember-light-table/components/lt-row", ["exports", "@ember/component", "@ember/object/computed", "@ember/template-factory"], function (_exports, _component, _computed, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.columns as |column|}}
    {{component
      (concat 'light-table/cells/' column.cellType)
      column=column
      row=this.row
      table=this.table
      rawValue=(get this.row column.valuePath)
      tableActions=this.tableActions
      extra=this.extra
    }}
  {{/each}}
  */
  {
    "id": "CV9OYJg4",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"columns\"]]],null]],null],null,[[[1,\"  \"],[46,[28,[37,3],[\"light-table/cells/\",[30,1,[\"cellType\"]]],null],null,[[\"column\",\"row\",\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[30,1],[30,0,[\"row\"]],[30,0,[\"table\"]],[28,[37,4],[[30,0,[\"row\"]],[30,1,[\"valuePath\"]]],null],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]]]],null],[1,\"\\n\"]],[1]],null]],[\"column\"],false,[\"each\",\"-track-array\",\"component\",\"concat\",\"get\"]]",
    "moduleName": "ember-light-table/components/lt-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: 'tr',
    classNames: ['lt-row'],
    classNameBindings: ['isSelected', 'isExpanded', 'canExpand:is-expandable', 'canSelect:is-selectable', 'row.classNames'],
    attributeBindings: ['colspan', 'data-row-id'],
    columns: null,
    row: null,
    tableActions: null,
    extra: null,
    canExpand: false,
    canSelect: false,
    colspan: 1,
    isSelected: (0, _computed.readOnly)('row.selected'),
    isExpanded: (0, _computed.readOnly)('row.expanded')
  }));
});