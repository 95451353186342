define("ember-svg-jar/inlined/chart-types/pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M36.5 25.1l7.1-4.1c-3-4.4-7.4-7.1-12.6-7.4v8.3c2.2.3 4.1 1.5 5.5 3v.2zM25.1 36.5L21 43.6c-4.4-3-7.4-8-7.4-13.6 0-8.6 6.8-15.7 15.2-16.3V22c-4 .4-7.1 3.8-7.1 8 0 2.7 1.5 5 3.3 6.5h.1z\"/><path class=\"chart-type-color-fill\" d=\"M44.5 22.6l-7 4.4c.4.7.7 1.8.7 3 0 4.4-3.7 8.1-8.1 8.1-1.2 0-2.2 0-3-.6l-4.4 7.4c2.4.9 4.7 1.5 7.4 1.5 8.9 0 16.3-7.4 16.3-16.3 0-2.7-.6-5-1.8-7.4l-.1-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});