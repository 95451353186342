define("ember-svg-jar/inlined/ibm/restart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 18A10 10 0 1116 8h6.182l-3.584 3.585L20 13l6-6-6-6-1.402 1.414L22.185 6H16a12 12 0 1012 12z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});