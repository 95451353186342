define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.535 9.75V30h-17.25V9.75h17.25zm0-2.25h-17.25a2.25 2.25 0 00-2.25 2.25V30a2.25 2.25 0 002.25 2.25h17.25a2.25 2.25 0 002.25-2.25V9.75a2.25 2.25 0 00-2.25-2.25z\"/><path d=\"M2.535 18.75H.285V3A2.25 2.25 0 012.535.75h15.75V3H2.535v15.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 33",
      "fill": "currentColor"
    }
  };
});