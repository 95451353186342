define("ember-svg-jar/inlined/chart-types/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M45.7 36.3v1.2l-.1.4-.1.4-.1.4-.2.3-.1.4-.1.3-.2.3-.2.3-.2.3-.2.3-.2.3-.3.3-.3.2-.3.3-.3.2-.3.2-.3.2-.3.2-.3.2-.3.1-.4.1-.3.1-.3.1-.4.1-.4.1-.4.1-.4.1-.3.2H38l-.4.1h-5.2l-.3-.1h-.4l-.4-.1-.3-.1-.4-.1-.3-.1-.3-.1-.4-.1-.3-.1-.4-.1-.3-.1-.4-.1-.3-.1-.3-.1-.3-.1-.3-.1-.3-.1-.3-.1.1-.4v-.3l.1-.4.1-.4v-.4l.1-.3.1-.4v-.4l.1-.3v-.4l.1-.4.1-.4v-.3l.1-.4.3.2.4.1.3.2.4.1.3.1.4.1.4.1.4.1.4.1.4.1.3.1.4.1.3.1.4.1.4.1h3.5l.4-.1.4-.1.3-.1.3-.1.3-.1.3-.1.3-.2.3-.3.2-.3.2-.3.1-.3.1-.4v-1.2l-.1-.3-.4-.5-.2-.3-.2-.3-.3-.3-.3-.2-.2-.1-.3-.1-.3-.1-.2-.1-.3-.1-.4-.1-.4-.1h-.4l-.4-.1h-3.6l-.1-.4v-.4l-.1-.4-.1-.4v-.4l-.1-.4V30l-.1-.4v-.4l-.1-.4-.1-.4V28l-.1-.4h4.6l.4-.1.3-.1.4-.1.3-.1.3-.1.3-.1.3-.1.3-.2.3-.3.2-.3.2-.3.1-.4.1-.3v-.8l-.1-.4-.1-.3-.2-.3-.3-.3-.3-.3-.3-.1-.3-.1-.2-.2-.3-.1-.3-.1-.4-.1h-3.2l-.4.1-.4.1-.4.1-.4.1-.4.1-.4.1-.3.1-.4.1-.3.1-.3.1-.3.1-.3.2-.3.2-.3.2-.3.2-.1-.4v-.4l-.2-.1-.1-.4v-.4l-.1-.4-.1-.4v-.7l-.1-.4-.1-.3v-.4l-.1-.4.3-.2.3-.1.3-.2.3-.1.3-.1.3-.1.3-.1.3-.1.3-.1.4-.1.4-.1.4-.1.3-.1.3-.1.3-.1.3-.1.3-.1h.4l.3-.1h5.6l.4.1.3.1.3.1.3.1.4.1.4.1.3.1.4.1.3.1.3.1.3.2.3.2.3.2.3.2.3.2.3.3.2.3.2.3.2.3.2.3.1.3.1.3.1.3.1.3.1.4.1.3V25l-.1.4.1.3-.1.3-.2.4-.1.4-.2.3-.2.3-.2.3-.3.3-.3.3-.4.3-.3.2-.3.2-.4.3-.3.2-.3.1-.4.1-.3.1-.4.2.4.1.4.1.4.1.4.1.3.2.3.2.3.2.3.2.3.2.3.2.3.2.3.2.2.3.2.3.2.3.1.3.1.3.1.3.1.3.1.4.1.4v1.4z\"/><path class=\"chart-type-color-fill\" d=\"M23.7 25.2c0 .2-.1.4-.2.5-.1.2-.3.3-.5.3H12.2c-.2 0-.4-.1-.5-.2-.2-.2-.2-.3-.2-.5s.1-.4.2-.5l5.4-5.4c.2-.2.3-.2.5-.2s.4.1.5.2l5.4 5.4c.2 0 .2.2.2.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});