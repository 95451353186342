define("ember-svg-jar/inlined/data-room-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.5 12h-.01a.5.5 0 01-.47-.363l-1.585-5.542-.967 2.58A.5.5 0 019 9H7V8h1.653l1.379-3.676a.5.5 0 01.949.039l1.556 5.446.989-2.967A.5.5 0 0114 6.5h2v1h-1.64l-1.386 4.158A.499.499 0 0112.5 12zm-5 3.5h-1V12A1.502 1.502 0 005 10.5H3A1.502 1.502 0 001.5 12v3.5h-1V12A2.503 2.503 0 013 9.5h2A2.503 2.503 0 017.5 12v3.5zM4 4.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0-1a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  };
});