define("ember-svg-jar/inlined/one-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 4.667C2 3.747 2.737 3 3.647 3h24.706C29.263 3 30 3.746 30 4.667v21.666c0 .92-.737 1.667-1.647 1.667H3.647C2.737 28 2 27.254 2 26.333V4.667zm3.294 1.666h21.412v18.334H5.294V6.333z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});