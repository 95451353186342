define("torii-salesforce-oauth2/torii-providers/salesforce-oauth2", ["exports", "torii/providers/oauth2-code", "torii/configuration"], function (_exports, _oauth2Code, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _oauth2Code.default.extend({
    name: 'salesforce-oauth2',
    baseUrl: 'https://login.salesforce.com/services/oauth2/authorize',
    // additional params that this provider requires
    optionalUrlParams: Object.freeze(['scope', 'display', 'code_challenge', 'immediate', 'login_hint', 'nonce', 'prompt']),
    responseParams: Object.freeze(['code', 'state']),
    scope: (0, _configuration.configurable)('scope', 'api'),
    display: (0, _configuration.configurable)('display', ''),
    codeChallenge: (0, _configuration.configurable)('codeChallenge', ''),
    immediate: (0, _configuration.configurable)('immediate', ''),
    loginHint: (0, _configuration.configurable)('loginHint', ''),
    nonce: (0, _configuration.configurable)('nonce', ''),
    prompt: (0, _configuration.configurable)('prompt', ''),
    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      return this._super();
    })
  });
});