define("ember-svg-jar/inlined/ibm/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.707 19.293L26 16.586V13a10.014 10.014 0 00-9-9.95V1h-2v2.05A10.014 10.014 0 006 13v3.586l-2.707 2.707A1 1 0 003 20v3a1 1 0 001 1h7v.777a5.152 5.152 0 004.5 5.198A5.005 5.005 0 0021 25v-1h7a1 1 0 001-1v-3a1 1 0 00-.293-.707zM19 25a3 3 0 01-6 0v-1h6v1zm8-3H5v-1.586l2.707-2.707A1 1 0 008 17v-4a8 8 0 0116 0v4a1 1 0 00.293.707L27 20.414V22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});