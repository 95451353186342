define("ember-light-table/components/columns/base", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-light-table/mixins/draggable-column", "ember-light-table/utils/css-styleify", "@ember/template-factory"], function (_exports, _component, _object, _computed, _utils, _draggableColumn, _cssStyleify, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.column.component}}
    {{component
      (ensure-safe-component this.column.component)
      column=this.column
      table=this.table
      tableActions=this.tableActions
      extra=this.extra
      sortIcons=this.sortIcons
    }}
  {{else}}
    {{#if (and this.sortIcons.iconComponent this.sortIconProperty)}}
      {{component
        (ensure-safe-component this.sortIcons.iconComponent)
        sortIcons=this.sortIcons
        sortIconProperty=this.sortIconProperty
      }}
    {{else if this.sortIconProperty}}
      <i class='lt-sort-icon {{get this.sortIcons this.sortIconProperty}}'></i>
    {{/if}}
    {{this.label}}
  {{/if}}
  
  {{#if this.isResizable}}
    <LtColumnResizer
      @column={{this.column}}
      @table={{this.table}}
      @resizeOnDrag={{this.resizeOnDrag}}
      @isResizing={{mut this.isResizing}}
      @onColumnResized={{action this.onColumnResized this.column}}
    />
  {{/if}}
  */
  {
    "id": "Hby//Kdu",
    "block": "[[[41,[30,0,[\"column\",\"component\"]],[[[1,\"  \"],[46,[28,[37,2],[[30,0,[\"column\",\"component\"]]],null],null,[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[30,0,[\"column\"]],[30,0,[\"table\"]],[30,0,[\"tableActions\"]],[30,0,[\"extra\"]],[30,0,[\"sortIcons\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,0,[\"sortIcons\",\"iconComponent\"]],[30,0,[\"sortIconProperty\"]]],null],[[[1,\"    \"],[46,[28,[37,2],[[30,0,[\"sortIcons\",\"iconComponent\"]]],null],null,[[\"sortIcons\",\"sortIconProperty\"],[[30,0,[\"sortIcons\"]],[30,0,[\"sortIconProperty\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"sortIconProperty\"]],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"lt-sort-icon \",[28,[37,4],[[30,0,[\"sortIcons\"]],[30,0,[\"sortIconProperty\"]]],null]]]],[12],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isResizable\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@column\",\"@table\",\"@resizeOnDrag\",\"@isResizing\",\"@onColumnResized\"],[[30,0,[\"column\"]],[30,0,[\"table\"]],[30,0,[\"resizeOnDrag\"]],[28,[37,6],[[30,0,[\"isResizing\"]]],null],[28,[37,7],[[30,0],[30,0,[\"onColumnResized\"]],[30,0,[\"column\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"component\",\"ensure-safe-component\",\"and\",\"get\",\"lt-column-resizer\",\"mut\",\"action\"]]",
    "moduleName": "ember-light-table/components/columns/base.hbs",
    "isStrictMode": false
  });

  /**
   * @module Light Table
   * @submodule Column Types
   */

  /**
   * @module Column Types
   * @class Base Column
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_draggableColumn.default, {
    tagName: 'th',
    classNames: ['lt-column'],
    attributeBindings: ['style', 'colspan', 'rowspan'],
    classNameBindings: ['align', 'isGroupColumn:lt-group-column', 'isHideable', 'isSortable', 'isSorted', 'isResizable', 'isResizing', 'isDraggable', 'column.classNames'],
    isGroupColumn: (0, _computed.readOnly)('column.isGroupColumn'),
    isSortable: (0, _computed.readOnly)('column.sortable'),
    isSorted: (0, _computed.readOnly)('column.sorted'),
    isHideable: (0, _computed.readOnly)('column.hideable'),
    isResizable: (0, _computed.readOnly)('column.resizable'),
    isDraggable: (0, _computed.readOnly)('column.draggable'),
    isResizing: false,
    style: (0, _object.computed)('column.width', function () {
      return (0, _cssStyleify.default)(this.column.getProperties(['width']));
    }),
    align: (0, _object.computed)('column.align', function () {
      return `align-${this.column.align}`;
    }),
    /**
     * @property label
     * @type {String}
     */
    label: (0, _computed.oneWay)('column.label'),
    /**
     * @property table
     * @type {Table}
     */
    table: null,
    /**
     * @property column
     * @type {Column}
     */
    column: null,
    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * @property sortIcons
     * @type {Object}
     */
    sortIcons: null,
    /**
     * @property sortIconProperty
     * @type {String|null}
     * @private
     */
    sortIconProperty: (0, _object.computed)('column.{sortable,sorted,ascending}', function () {
      let sorted = this.column.sorted;
      if (sorted) {
        let ascending = this.column.ascending;
        return ascending ? 'iconAscending' : 'iconDescending';
      }
      let sortable = this.column.sortable;
      return sortable ? 'iconSortable' : null;
    }),
    /**
     * @property colspan
     * @type {Number}
     */
    colspan: (0, _object.computed)('column', 'column.visibleSubColumns.[]', function () {
      let subColumns = this.column.visibleSubColumns;
      return !(0, _utils.isEmpty)(subColumns) ? subColumns.length : 1;
    }),
    /**
     * @property rowspan
     * @type {Number}
     */
    rowspan: (0, _object.computed)('_rowspan', 'column.visibleSubColumns.[]', {
      get() {
        if (this._rowspan) {
          return this._rowspan;
        }
        let subColumns = this.column.visibleSubColumns;
        return !(0, _utils.isEmpty)(subColumns) ? 1 : 2;
      },
      set(key, value) {
        return (0, _object.set)(this, '_rowspan', value);
      }
    })
  }));
});