define("ember-svg-jar/inlined/praying-hands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.392 2.847a3 3 0 00-4.892-.968l-4.035 9.414A4.967 4.967 0 006 14.829V22l-4.5 3 1 1.5L8 23v-8.171a2.98 2.98 0 01.879-2.122l4.035-9.414a1 1 0 011.707.704V4a.994.994 0 01-.075.383c-.05.121-.046.117-.046.117l-2.328 7.743A3.972 3.972 0 0011 15.07V20h2v-4a1 1 0 012 0v7a3.003 3.003 0 01-3 3h-1.333l-4.53 3.4 1.2 1.6 3.996-3H12a5.005 5.005 0 005-5v-7a2.994 2.994 0 00-2.78-2.978L16.1 6s.143-.487.294-.851.228-.755.227-1.149v-.01c0-.392-.078-.78-.229-1.143z\"/><path d=\"M15.608 2.847a3 3 0 014.892-.968l4.035 9.414A4.966 4.966 0 0126 14.829V22l4.5 3-1 1.5L24 23v-8.171a2.978 2.978 0 00-.879-2.122l-4.035-9.414a1 1 0 00-1.707.704V4c0 .131.025.262.075.383.05.121.046.117.046.117l2.328 7.743A3.972 3.972 0 0121 15.07V20h-2v-4a1 1 0 00-2 0v7a3.003 3.003 0 003 3h1.333l4.53 3.4-1.2 1.6-3.996-3H20a5.005 5.005 0 01-5-5v-7a2.994 2.994 0 012.78-2.978L15.9 6s-.143-.487-.294-.851A2.977 2.977 0 0115.379 4v-.01c0-.392.078-.78.229-1.143z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});