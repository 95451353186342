define("ember-svg-jar/inlined/loom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 14.367h-8.227l7.125-4.113-1.565-2.711-7.125 4.113 4.113-7.124-2.711-1.566-4.113 7.125V1.864h-3.13v8.228l-4.114-7.126-2.71 1.565 4.113 7.125-7.125-4.113-1.565 2.71 7.125 4.114H1.864v3.13h8.227L2.966 21.61l1.565 2.711 7.124-4.113-4.113 7.125 2.71 1.565 4.114-7.125V30h3.13v-8.227l4.113 7.125 2.71-1.565-4.113-7.125 7.126 4.113 1.564-2.71-7.124-4.114h8.226v-3.13H30zM15.932 20.19a4.272 4.272 0 110-8.545 4.272 4.272 0 010 8.545z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});