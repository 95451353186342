define("ember-svg-jar/inlined/file-zip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.672 25.01h-1.567c-.431 0-.782-.29-.782-.65 0-.357.35-.648.782-.648h1.567c.431 0 .782.29.782.649 0 .358-.35.649-.782.649zm0-2.782h-1.567c-.431 0-.782-.29-.782-.649 0-.357.35-.649.782-.649h1.567c.431 0 .782.292.782.649 0 .359-.35.649-.782.649zm0-2.78h-1.567c-.431 0-.782-.29-.782-.649 0-.358.35-.649.782-.649h1.567c.431 0 .782.29.782.649 0 .36-.35.649-.782.649zM10.538 26.4H8.964c-.431 0-.782-.29-.782-.648 0-.359.35-.65.782-.65h1.568c.439 0 .79.291.79.65 0 .358-.351.649-.784.649zm0-2.78H8.964c-.431 0-.782-.292-.782-.65 0-.358.35-.649.782-.649h1.568c.439 0 .79.291.79.65 0 .357-.351.648-.784.648zm0-2.78H8.964c-.431 0-.782-.293-.782-.65 0-.359.35-.649.782-.649h1.568c.439 0 .79.29.79.65 0 .356-.351.648-.784.648z\"/><path d=\"M12.788 15.955a2.209 2.209 0 01-1.463.538c-.53 0-1.063-.18-1.464-.538-.573-.508-.76-1.273-.497-1.876l.818-3.313c.059-.235.293-.402.564-.402H11.9c.132 0 .26.04.363.112a.508.508 0 01.202.29l.797 3.241c.14.331.169.69.085 1.036a1.795 1.795 0 01-.56.912zm-.633-1.656l-.72-2.908H11.2l-.741 2.979a.747.747 0 00.212.859.997.997 0 001.294 0c.256-.224.337-.562.19-.93z\" clip-rule=\"evenodd\"/><path d=\"M11.325 16.493h-.002.005-.003z\"/><path fill-rule=\"evenodd\" d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H14.275a.584.584 0 00.18-.414c0-.358-.351-.649-.783-.649h-1.567c-.431 0-.782.291-.782.65 0 .156.067.3.18.413H6.555z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "#345072",
      "viewBox": "0 0 32 32"
    }
  };
});