define("ember-svg-jar/inlined/settings-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.873 17.3c.066-.39.066-.845.066-1.3 0-.455-.066-.845-.066-1.3l2.792-2.145c.266-.195.332-.52.133-.845l-2.66-4.485c-.133-.26-.531-.39-.798-.26l-3.324 1.3a9.678 9.678 0 00-2.26-1.3l-.465-3.445a.713.713 0 00-.665-.52h-5.319a.712.712 0 00-.664.52l-.532 3.445c-.798.325-1.53.78-2.26 1.3l-3.325-1.3c-.332-.13-.665 0-.798.26l-2.66 4.485c-.132.26-.066.65.134.845L6.06 14.7c0 .455-.067.845-.067 1.3 0 .455.067.845.067 1.3l-2.792 2.145c-.266.195-.333.52-.133.845l2.66 4.485c.132.26.53.39.797.26l3.324-1.3c.665.52 1.462.975 2.26 1.3l.532 3.445c.067.325.332.52.665.52h5.318c.333 0 .599-.26.665-.52l.533-3.445c.797-.325 1.529-.78 2.26-1.3l3.324 1.3c.332.13.665 0 .798-.26l2.66-4.485c.132-.26.065-.65-.134-.845L25.873 17.3zm-9.906 3.25c-2.593 0-4.654-2.015-4.654-4.55 0-2.535 2.06-4.55 4.654-4.55 2.593 0 4.654 2.015 4.654 4.55 0 2.535-2.061 4.55-4.654 4.55z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});