define("ember-svg-jar/inlined/properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.623 4C2.726 4 2 4.746 2 5.667v21.666C2 28.253 2.727 29 3.623 29h24.34c.897 0 1.623-.746 1.623-1.667V5.667c0-.92-.726-1.667-1.622-1.667H3.622zM4.3 6.4h23.2V11H4.3V6.4zm0 6.6v13.6h23.2V13H4.3z\"/><path d=\"M23 17.786a1 1 0 01-1 1H8a1 1 0 110-2h14a1 1 0 011 1zM18 21.786a1 1 0 01-1 1H8a1 1 0 110-2h9a1 1 0 011 1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});