define("ember-svg-jar/inlined/font-awesome/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.773 27.466l-1.387 1.387a1.494 1.494 0 01-2.119 0L3.117 16.71a1.494 1.494 0 010-2.118l12.15-12.15a1.494 1.494 0 012.119 0l1.387 1.387a1.502 1.502 0 01-.025 2.144l-7.531 7.175h17.962c.832 0 1.5.669 1.5 1.5v2c0 .831-.668 1.5-1.5 1.5H11.218l7.531 7.175a1.49 1.49 0 01.025 2.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 33 32"
    }
  };
});