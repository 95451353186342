define("ember-svg-jar/inlined/book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.937 4.69v-.711c0-.045.019-.085.054-.123A.165.165 0 015.11 3.8h4.467a.17.17 0 01.12.056.17.17 0 01.052.121v.71a.175.175 0 01-.054.124.165.165 0 01-.118.055H5.109a.16.16 0 01-.118-.056.17.17 0 01-.054-.121zm.173 1.954a.16.16 0 01-.12-.056.17.17 0 01-.053-.12v-.712a.18.18 0 01.054-.123.165.165 0 01.119-.055h4.467a.17.17 0 01.12.056.17.17 0 01.052.121v.711a.175.175 0 01-.054.123.165.165 0 01-.118.055H5.11zm6.422 3.555c-.043.133-.064.37-.064.711 0 .341.02.578.064.71.08.013.154.056.204.123.05.067.077.149.075.233v.356a.35.35 0 01-.096.256.333.333 0 01-.247.1H3.906a1.63 1.63 0 01-1.213-.522 1.714 1.714 0 01-.379-.574 1.762 1.762 0 01-.126-.683v-7.82c0-.49.168-.907.504-1.255a1.637 1.637 0 011.215-.522h7.562c.1 0 .183.034.247.1a.353.353 0 01.096.256v8.176a.378.378 0 01-.075.233.313.313 0 01-.205.122zm-.88 0H3.906a.642.642 0 00-.483.211.684.684 0 00-.204.5.702.702 0 00.204.5.66.66 0 00.483.21h6.746a4.955 4.955 0 01-.044-.71c0-.282.015-.518.043-.711h.001zm.128-7.82H3.906a.641.641 0 00-.483.211.684.684 0 00-.204.5v6.199c.214-.104.443-.156.687-.156h6.874V2.379z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 14 14"
    }
  };
});