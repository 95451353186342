define("ember-svg-jar/inlined/pin-unfilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.59 13.31L30 11.9 20 2l-1.31 1.42 1.18 1.18-11.49 9.72-1.72-1.71L5.25 14l5.66 5.68L2 28.58 3.41 30l8.91-8.91L18 26.75l1.39-1.42-1.71-1.71 9.72-11.49 1.19 1.18zM16.26 22.2L9.8 15.74 21.29 6 26 10.71 16.26 22.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});