define("ember-svg-jar/inlined/collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.64 24.103l-1.02 1.052a1.076 1.076 0 01-1.56 0l-8.938-9.205a1.157 1.157 0 010-1.606l8.939-9.21a1.076 1.076 0 011.559 0l1.02 1.052c.437.45.428 1.184-.018 1.625l-5.54 5.439h13.214c.612 0 1.104.507 1.104 1.137v1.516c0 .63-.492 1.137-1.104 1.137H16.081l5.541 5.438c.45.441.46 1.175.018 1.625zM4.963 6.541c0-.957-.753-1.732-1.681-1.732-.93 0-1.682.775-1.682 1.732v17.326c0 .957.753 1.733 1.682 1.733.928 0 1.681-.776 1.681-1.733V6.541z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});