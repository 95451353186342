define("ember-svg-jar/inlined/navigation/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.222 4.36a14 14 0 1115.556 23.28A14 14 0 018.222 4.36zM10 24.38v2.01a11.92 11.92 0 0012 0v-2A3.22 3.22 0 0019 21h-6a3.22 3.22 0 00-3 3.38zm14-.07v.59a12 12 0 10-16 0v-.59A5.2 5.2 0 0113 19a4.993 4.993 0 01-1.904-3.024A5 5 0 1119 19a5.21 5.21 0 015 5.31zm-9.667-6.816a3 3 0 103.333-4.987 3 3 0 00-3.333 4.987z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});