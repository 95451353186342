define("ember-svg-jar/inlined/rich-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.419 23.926h-.576l-3.217-8.912a.787.787 0 00-.746-.534h-1.16a.788.788 0 00-.746.534l-3.217 8.912h-.576a.394.394 0 00-.393.393v.787a.394.394 0 00.393.394h3.15a.394.394 0 00.394-.394v-.787a.394.394 0 00-.394-.393h-.482l.574-1.574h3.754l.574 1.574h-.482a.394.394 0 00-.394.393v.787a.394.394 0 00.394.394h3.15a.394.394 0 00.393-.394v-.787a.394.394 0 00-.393-.393zm-6.28-3.542l1.161-3.185 1.16 3.185h-2.32zM0 1.5a1 1 0 011-1h26a1 1 0 011 1v1.998a1 1 0 01-1 1H1a1 1 0 01-1-1V1.5zM0 8.496a1 1 0 011-1h26a1 1 0 011 1v1.999a1 1 0 01-1 1H1a1 1 0 01-1-1V8.496zM0 15.492a1 1 0 011-.999h14a1 1 0 011 1v1.998a1 1 0 01-1 1H1a1 1 0 01-1-1v-1.998zM0 22.489a1 1 0 011-1h9a1 1 0 011 1v1.999a1 1 0 01-1 1H1a1 1 0 01-1-1v-1.999z\"/>",
    "attrs": {
      "viewBox": "0 0 28 26",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});