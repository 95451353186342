define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.741 27c-.573 0-1.026-.284-1.503-.587-.477-.302-1.03-.88-1.263-1.137-.262-.289-.997-1.136-.997-1.136l-.096-.095h.026L.814 17.25A2.685 2.685 0 010 15.312a2.69 2.69 0 01.85-1.925 2.98 2.98 0 012.026-.807 2.984 2.984 0 012.04.774l7.094 6.74L27.046 5.808A2.98 2.98 0 0129.097 5c.77 0 1.508.29 2.052.807A2.69 2.69 0 0132 7.756a2.69 2.69 0 01-.85 1.95L19.947 20.352l-.002-.003-4.389 4.17.268-.254-2.031 1.93a2.91 2.91 0 01-.941.597c-.352.138-.73.209-1.11.208z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});