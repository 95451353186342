define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 23a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM17 12h-2v9h2v-9z\"/><path d=\"M29 30H3a1 1 0 01-.887-1.461l13-25a1 1 0 011.774 0l13 25A1 1 0 0129 30zM4.65 28h22.7l.001-.003L16.002 6.17h-.004L4.648 27.997l.003.003z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});