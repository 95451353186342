define("ember-svg-jar/inlined/activity-icon-visit-data-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zM26.5 9.875h-9.042l-3.5-2.625H7.25V5.5h6.708c.379 0 .747.123 1.05.35l3.034 2.275H26.5v1.75zM24.75 26.5H13V21a1 1 0 00-1-1H6a.995.995 0 00-.5.134v-8.509a1.752 1.752 0 011.75-1.75h4.958c.379 0 .747.123 1.05.35l3.034 2.275h8.458a1.752 1.752 0 011.75 1.75v10.5a1.752 1.752 0 01-1.75 1.75zM7 22v1h2.293L6 26.293l.707.707L10 23.707V26h1v-4H7z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});