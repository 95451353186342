define("ember-svg-jar/inlined/font-awesome/bell-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.462 22.643c-1.208-1.297-3.467-3.25-3.467-9.643 0-4.856-3.405-8.744-7.996-9.697V2A2 2 0 1014 2v1.303C9.41 4.256 6.005 8.143 6.005 13c0 6.394-2.26 8.346-3.467 9.643A1.953 1.953 0 002 24c.007 1.025.811 2 2.006 2h23.988c1.195 0 2-.975 2.006-2a1.951 1.951 0 00-.538-1.357zM6.22 23c1.326-1.748 2.776-4.646 2.783-9.964 0-.012-.004-.024-.004-.036a7 7 0 1114 0c0 .012-.004.024-.004.036.007 5.319 1.457 8.217 2.783 9.964H6.221zM16 32a4 4 0 003.998-4h-7.996A4 4 0 0016 32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});