define("ember-svg-jar/inlined/font-awesome/check-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.869 27.463l-10.4-10.4a1.6 1.6 0 010-2.263l2.262-2.263a1.6 1.6 0 012.263 0L12 19.543 27.006 4.537a1.6 1.6 0 012.263 0L31.53 6.8a1.6 1.6 0 010 2.263l-18.4 18.4a1.6 1.6 0 01-2.262 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});