define("ember-svg-jar/inlined/portfolio-formula", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 4h7.17a2 2 0 011.42.59L16 8h12a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm10.59 5.41L11.17 6H4v20h24V10H15.17l-.58-.59z\"/><path d=\"M17.768 13.945c-.597.58-1.574.263-2.009-1.053 0 0-.706.42-.841 3.475-.002.026 1.602 0 1.602 0l-.11.895h-1.737s-.217 2.895-1.086 4.475c-.333.606-1.14 1.317-2.39 1.527-.67.113-1.499-.045-1.945-.396-.385-.302-.296-.988.046-1.289 1.194-1.053 1.954 1.106 1.954 1.106.815-.053 1.738-5.423 1.738-5.423h-1.52l.108-.895h1.52s.185-1.22.381-1.79c.217-.632.814-1.58 1.411-1.895.598-.317.978-.422 2.01-.37 1.032.053 1.52 1 .868 1.633zM19.718 19.545c-.027-1.566-1.798-1.889-2.861-.82 0 0-.027.075.136.025 1.393-.424 1.581 1.789 1.581 1.789l-.436.596c-2.017-.696-2.234 1.49-.927 1.341.552-.062 1.5-1.416 1.5-1.416.397 2.175 2.1 1.299 2.732.888.047-.03.015-.1-.042-.089-1.452.275-1.574-1.867-1.574-1.867s.326-.594.409-.572c1.717.473 2.36-1.316 1.117-1.316-.872 0-1.635 1.44-1.635 1.44z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});