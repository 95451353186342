define("ember-svg-jar/inlined/file-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.5 30.188a2.875 2.875 0 100-5.75 2.875 2.875 0 000 5.75z\" fill=\"#F4F4F5\"/><path d=\"M34.68 26.563a12.42 12.42 0 00-11.18-7.875 12.42 12.42 0 00-11.18 7.875l-.32.75.32.75a12.42 12.42 0 0011.18 7.875 12.42 12.42 0 0011.18-7.876l.32-.75-.32-.75zm-11.18 6.5a5.75 5.75 0 115.75-5.75 5.757 5.757 0 01-5.75 5.75z\" fill=\"#F4F4F5\"/><path d=\"M39.313 4.313H7.686a2.875 2.875 0 00-2.875 2.875v31.625a2.875 2.875 0 002.875 2.874h31.625a2.875 2.875 0 002.876-2.874V7.186a2.875 2.875 0 00-2.876-2.875zM7.686 7.188h31.625v5.75H7.689v-5.75zm0 31.625v-23h31.625v23H7.689z\" fill=\"#F4F4F5\"/>",
    "attrs": {
      "width": "47",
      "height": "46",
      "viewBox": "0 0 47 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});