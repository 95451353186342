define("ember-svg-jar/inlined/file-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.233 10.831L18.44 3.037c-.383-.511-1.022-.767-1.661-.767H6.556A2.563 2.563 0 004 4.826v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.826V12.62c0-.639-.256-1.277-.767-1.789zM6.556 27.826v-23h10.222l7.666 7.666v15.334H6.556z\" fill=\"#2563EB\"/><path d=\"M18.3 13l-.909 8.611L16 13h-2l-1.391 8.611L11.7 13H10l1.36 10h2.28L15 14.626 16.36 23h2.28L20 13h-1.7z\" fill=\"#2563EB\"/>",
    "attrs": {
      "width": "32",
      "height": "33",
      "viewBox": "0 0 32 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});