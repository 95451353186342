define("ember-svg-jar/inlined/activity-icon-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 19.502l-1.295-1.377-.705.75L15 21l4-4.25-.705-.75L15 19.502z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zm5.82-18.828l-4.2-4A.547.547 0 0017.2 8h-6c-.318 0-.623.12-.848.335a1.116 1.116 0 00-.352.808v13.714c0 .303.126.594.351.808.225.215.53.335.849.335h9.6c.318 0 .623-.12.848-.335.225-.214.352-.505.352-.808V12.572a.5.5 0 00-.18-.4z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});