define("ember-svg-jar/inlined/excel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.03 7.715l-6-1.06v7.834c0 .358.29.648.647.648H15.03c.357 0 .647-.29.647-.648v-2.886L10.03 7.715z\" fill=\"#185C37\"/><path d=\"M10.03 1H4.677a.647.647 0 00-.647.648v2.886l6 3.535 3.177 1.06 2.47-1.06V4.534L10.03 1z\" fill=\"#21A366\"/><path d=\"M4.03 4.534h6V8.07h-6V4.534z\" fill=\"#107C41\"/><path opacity=\".1\" d=\"M8.324 3.827H4.03v8.836h4.294a.65.65 0 00.647-.648v-7.54a.65.65 0 00-.647-.648z\" fill=\"#000\"/><path opacity=\".2\" d=\"M7.971 4.18H4.03v8.837H7.97a.65.65 0 00.647-.648v-7.54a.65.65 0 00-.647-.648z\" fill=\"#000\"/><path opacity=\".2\" d=\"M7.971 4.18H4.03v8.13H7.97a.65.65 0 00.647-.648V4.829a.65.65 0 00-.647-.648z\" fill=\"#000\"/><path opacity=\".2\" d=\"M7.618 4.18H4.03v8.13h3.588a.65.65 0 00.647-.648V4.829a.65.65 0 00-.647-.648z\" fill=\"#000\"/><path d=\"M1.147 4.18h6.471c.358 0 .647.29.647.649v6.48c0 .357-.29.647-.647.647H1.147A.647.647 0 01.5 11.31v-6.48c0-.358.29-.648.647-.648z\" fill=\"url(#paint0_linear_3_465)\"/><path d=\"M2.504 10.174l1.36-2.111-1.246-2.1H3.62l.68 1.343c.063.127.106.222.13.284h.009a5.3 5.3 0 01.14-.296l.728-1.33h.921L4.95 8.05l1.312 2.123h-.98L4.496 8.7a1.238 1.238 0 01-.094-.197H4.39a.933.933 0 01-.09.191l-.81 1.48h-.986z\" fill=\"#fff\"/><path d=\"M15.03 1h-5v3.534h5.648V1.648A.647.647 0 0015.03 1z\" fill=\"#33C481\"/><path d=\"M10.03 8.069h5.648v3.534H10.03V8.069z\" fill=\"#107C41\"/><defs><linearGradient id=\"paint0_linear_3_465\" x1=\"1.849\" y1=\"3.675\" x2=\"6.926\" y2=\"12.457\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#18884F\"/><stop offset=\".5\" stop-color=\"#117E43\"/><stop offset=\"1\" stop-color=\"#0B6631\"/></linearGradient></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});