define("ember-svg-jar/inlined/date-with-exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.96 4.75h-2.756V1.703A.698.698 0 0020.515 1H18.22a.698.698 0 00-.688.703V4.75h-7.347V1.703A.698.698 0 009.494 1H7.2a.698.698 0 00-.689.703V4.75H3.755C2.235 4.75 1 6.01 1 7.563v20.625C1 29.74 2.234 31 3.755 31H23.96c1.386 0 2.533-1.046 2.727-2.406a8.586 8.586 0 01-2.115.263 8.546 8.546 0 01-3.327-.67H4.1a.35.35 0 01-.344-.351V10.375H23.96v1.36a8.678 8.678 0 012.755.25V7.562c0-1.553-1.234-2.813-2.755-2.813zm8.111 15.536a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM25.207 16h-1.314c-.35 0-.393.214-.393.343v.257l.264 4.686c.043.343.215.428.45.428h.686c.264 0 .436-.085.479-.428l.264-4.686v-.257c0-.129-.043-.343-.436-.343zm-1.23 8.39c.175.119.383.181.594.181h.036a1.071 1.071 0 10-.63-.18z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});