define("ember-svg-jar/inlined/sharing-dropdown-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#979797\" d=\"M1269.09 146.864l7.72 1.283-4.114 5.663\"/><path stroke=\"#979797\" d=\"M1275 203s36-21 1-51\" stroke-dasharray=\"3 3\"/></g>",
    "attrs": {
      "width": "25",
      "height": "50",
      "viewBox": "1267 144 25 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});