define("ember-svg-jar/inlined/presentation-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 10h-2v8h2v-8zm5 4h-2v4h2v-4zm-10-2h-2v6h2v-6z\"/><path d=\"M25 4h-8V2h-2v2H7a2 2 0 00-2 2v14a2 2 0 002 2h8v6h-4v2h10v-2h-4v-6h8a2 2 0 002-2V6a2 2 0 00-2-2zm0 16H7V6h18v14z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});