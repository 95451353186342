define("ember-svg-jar/inlined/table-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1 8.234C1 6.957 1.61 6 2.364 6h27.272C30.39 6 31 6.823 31 8.234c0 1.116-.61 2.233-1.364 2.233H2.364C1.61 10.467 1 9.51 1 8.234zM3.679 16.944c0-1.116.659-2.17 1.412-2.17h21.818c.753 0 1.412 1.054 1.412 2.17 0 1.117-.659 2.28-1.412 2.28H5.091c-.753 0-1.412-1.163-1.412-2.28zM10.546 23.549c-.754 0-1.403 1.113-1.403 2.33 0 1.116.65 2.121 1.402 2.121h10.91c.753 0 1.51-1.005 1.51-2.122 0-1.116-.757-2.329-1.51-2.329h-10.91z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});