define("ember-svg-jar/inlined/file-generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H6.556z\" class=\"Union\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "#6C767F",
      "viewBox": "0 0 32 32"
    }
  };
});