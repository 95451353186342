define("ember-svg-jar/inlined/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.708 1.5H6.292c-.99 0-1.792.845-1.792 1.886v25.228c0 1.041.802 1.886 1.792 1.886h19.416c.99 0 1.792-.845 1.792-1.886V3.386c0-1.042-.802-1.886-1.792-1.886zm0 13.551V28.69H6.292V3.314h19.416V15.05zM22 13v2H10v-2h12zm-2-2V9H10v2h10zm2 10v2H10v-2h12zm-2-2v-2H10v2h10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});