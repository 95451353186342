define("ember-svg-jar/inlined/activity-icon-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.018 18.598a.25.25 0 00.05.08h-.017l.342.378c.045.053.096.08.153.08a.143.143 0 00.076-.02.17.17 0 00.06-.06l1.876-2.246v4.95a.262.262 0 00.059.17c.04.047.089.07.145.07h.478a.174.174 0 00.078-.017.236.236 0 00.111-.13c.01-.029.015-.06.015-.091V16.81l1.874 2.247c.035.053.08.08.136.08.03-.001.058-.008.084-.022s.05-.034.07-.058l.324-.379a.253.253 0 00.05-.08A.283.283 0 0019 18.5a.215.215 0 00-.017-.09.185.185 0 00-.051-.07l-2.794-3.28A.172.172 0 0016 15a.227.227 0 00-.153.06l-2.797 3.28a.238.238 0 00-.051.16.28.28 0 00.018.098zm.05.08v.001h.001-.001z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M31 16c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C1 7.716 7.716 1 16 1c8.284 0 15 6.716 15 15zM17.62 8.172l4.2 4a.52.52 0 01.18.4v10.285c0 .303-.127.594-.352.808-.225.215-.53.335-.848.335h-9.6c-.318 0-.624-.12-.849-.335a1.116 1.116 0 01-.351-.808V9.143c0-.303.126-.594.351-.808A1.23 1.23 0 0111.2 8h6a.57.57 0 01.42.172z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});