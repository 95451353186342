define("ember-svg-jar/inlined/arrow-down-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 1a2 2 0 00-2 2v26a2 2 0 002 2h26a2 2 0 002-2V3a2 2 0 00-2-2H3zm19.923 11H9.077c-.958 0-1.437 1.275-.76 2.022l6.921 7.631a1.01 1.01 0 001.524 0l6.92-7.631c.678-.747.2-2.022-.759-2.022z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});