define("ember-svg-jar/inlined/file-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M9.074 24.9c.21.061.417.09.619.09l-.004.01c1.66 0 2.995-1.934 3.976-3.853a51.163 51.163 0 012.038-.94 12.118 12.118 0 012.37-.747c1.583 1.04 3.197 1.668 4.081 1.302.423-.173.664-.55.664-1.035 0-.45-.224-.843-.632-1.109-.795-.515-2.317-.54-3.9-.264a14.984 14.984 0 01-1.902-1.596 7.697 7.697 0 01-.644-.729c.527-1.842.917-4.09.274-5.086a1.235 1.235 0 00-1.035-.58h-.01c-.589 0-1.077.367-1.345 1.002-.474 1.139-.237 3.06.97 4.86a16.444 16.444 0 01-.63 1.727l-.189.436a43.686 43.686 0 01-.886 1.962c-3.184 1.56-4.764 2.787-4.706 3.655.017.244.154.685.89.896zm.288-.999a.812.812 0 01-.093-.03c.224-.358 1.176-1.033 2.694-1.86-.78 1.22-1.691 2.153-2.601 1.89zm12.412-4.116c-.198.148-.984-.018-2.038-.557h.002c.858-.052 1.552.04 1.881.255.144.094.157.171.155.302zm-6.844-1.42c.126-.286.282-.688.446-1.165v-.007l.021.024c.083.088.166.178.253.262.416.414.856.803 1.318 1.166-.592.163-1.164.368-1.682.592a81.36 81.36 0 00-.915.41l.22-.5.152-.35.187-.431zm-.33-6.61c.097-.235.221-.356.37-.358.07.003.115.02.17.101.314.488.177 1.845-.154 3.284-.593-1.257-.639-2.424-.387-3.027z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H6.556z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "#EC4C47",
      "viewBox": "0 0 32 32"
    }
  };
});