define("ember-svg-jar/inlined/file-excel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g class=\"Excel\"><path d=\"M17.502 13.383l-2.022 2.829-1.99-2.829h-2.078l2.994 4.12-3.088 4.244h2.093l2.092-2.93 2.1 2.93h2.079l-3.089-4.244 2.994-4.12h-2.085z\"/><path fill-rule=\"evenodd\" d=\"M26.233 10.56L18.44 2.768C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.555v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.555V12.35c0-.64-.256-1.278-.767-1.79zM6.556 27.556v-23h10.222l7.666 7.667v15.333H6.556z\" clip-rule=\"evenodd\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "#48A469",
      "viewBox": "0 0 32 32"
    }
  };
});