define("ember-svg-jar/inlined/portfolio-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.615 25.692h-4.307V30h4.307v-4.308zM31.154 25.692h-4.308V30h4.308v-4.308zM23.615 18.154h-4.307v4.308h4.307v-4.308zM31.154 18.154h-4.308v4.308h4.308v-4.308z\"/><path d=\"M17.154 25.692h-14V10.615H29V16h2.154v-5.385A2.156 2.156 0 0029 8.462h-6.462V4.154A2.156 2.156 0 0020.386 2h-8.616a2.156 2.156 0 00-2.154 2.154v4.308H3.154A2.156 2.156 0 001 10.615v15.077a2.156 2.156 0 002.154 2.154h14v-2.154zM11.769 4.154h8.616v4.308h-8.616V4.154z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});