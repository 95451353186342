define("ember-svg-jar/inlined/sprout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25 2a11.01 11.01 0 00-10.245 7.024A6.993 6.993 0 009 6H6v3a7.008 7.008 0 007 7h1v9.05a9.923 9.923 0 00-6.071 2.879l1.414 1.414a8 8 0 0112.309 1.213l1.661-1.113A9.98 9.98 0 0016 25.062V16h1A11.012 11.012 0 0028 5V2h-3zM13 14a5.006 5.006 0 01-5-5V8h1a5.006 5.006 0 015 5v1h-1zm13-9a9.01 9.01 0 01-9 9h-1v-1a9.01 9.01 0 019-9h1v1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});