define("ember-svg-jar/inlined/ibm/h2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.92 27h-9.68v-2.44l4.38-3.84c.747-.653 1.293-1.24 1.64-1.76.347-.534.52-1.107.52-1.72v-.26c0-.614-.2-1.08-.6-1.4-.4-.334-.887-.5-1.46-.5-.72 0-1.267.207-1.64.62-.373.4-.64.887-.8 1.46L20 16.28c.147-.467.353-.907.62-1.32.267-.427.6-.8 1-1.12.413-.32.893-.573 1.44-.76.547-.187 1.173-.28 1.88-.28.733 0 1.387.106 1.96.32.573.2 1.053.487 1.44.86.4.373.7.813.9 1.32.213.506.32 1.06.32 1.66a4.38 4.38 0 01-.28 1.6c-.187.48-.44.94-.76 1.38a9.6 9.6 0 01-1.12 1.24c-.427.387-.88.78-1.36 1.18l-2.94 2.42h6.82V27zM13.95 5v10.54L5 15.5V5H2v22h3v-8.54l8.95.04V27H17V5h-3.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});