define("ember-svg-jar/inlined/draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.188 4h-3.75a.945.945 0 00-.663.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.662.27h3.75a.945.945 0 00.663-.27.916.916 0 00.275-.653V4.923a.916.916 0 00-.275-.653.945.945 0 00-.662-.27zm9.374 0h-3.75a.945.945 0 00-.662.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.663.27h3.75a.945.945 0 00.662-.27.916.916 0 00.275-.653V4.923a.916.916 0 00-.275-.653.945.945 0 00-.663-.27zm-9.375 18.462h-3.75a.945.945 0 00-.662.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.662.27h3.75a.945.945 0 00.663-.27.916.916 0 00.275-.653v-3.692a.916.916 0 00-.275-.653.945.945 0 00-.662-.27zm9.376 0h-3.75a.945.945 0 00-.663.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.663.27h3.75a.945.945 0 00.662-.27.916.916 0 00.275-.653v-3.692a.916.916 0 00-.275-.653.945.945 0 00-.663-.27zm-9.375-9.231h-3.75a.945.945 0 00-.663.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.662.27h3.75a.945.945 0 00.663-.27.916.916 0 00.275-.653v-3.692a.916.916 0 00-.275-.653.945.945 0 00-.662-.27zm9.374 0h-3.75a.945.945 0 00-.662.27.916.916 0 00-.275.653v3.692c0 .245.099.48.275.653.175.173.414.27.663.27h3.75a.945.945 0 00.662-.27.916.916 0 00.275-.653v-3.692a.916.916 0 00-.275-.653.945.945 0 00-.663-.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});