define("ember-svg-jar/inlined/ibm/column-insert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 30h-6a2.002 2.002 0 01-2-2V10a2.002 2.002 0 012-2h6a2.002 2.002 0 012 2v18a2.002 2.002 0 01-2 2zm-6-20v18h6V10h-6zm-6-1l5.586-5.586L20.172 2 16 6.172 11.828 2l-1.414 1.414L16 9zm-6 21H4a2.002 2.002 0 01-2-2V10a2.002 2.002 0 012-2h6a2.002 2.002 0 012 2v18a2.002 2.002 0 01-2 2zM4 10v18h6V10H4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});