define("ember-svg-jar/inlined/dashboard-templates/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".3\" d=\"M1 11.994h5.122v11.71H1v-11.71zM9.049 13.053h5.122v10.66H9.049v-10.66zM25.878 13.456H31v10.247h-5.122V13.456zM17.83 5h5.121v18.703H17.83V5z\"/><path d=\"M1.41 19.2l.488.538 6.771-8.427 7.37 10.683 8.083-9.38L30.67 9.62 30.358 9 23.71 11.93l-7.67 9.005-7.37-10.87-7.26 9.134z\"/><path opacity=\".3\" d=\"M1 25.9h30v.731H1V25.9z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});