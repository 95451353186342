define("ember-svg-jar/inlined/file-powerpoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g class=\"power point\"><g class=\"Union\" fill=\"#DE5D34\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M11.318 12.454h4.656c2.602 0 3.834 1.743 3.698 3.776 0 2.179-1.233 3.776-3.698 3.776H13.51v2.903h-2.192V12.455zm2.19 5.373h2.466c1.095 0 1.505-.726 1.505-1.598 0-.87-.41-1.596-1.505-1.596h-2.465v3.194z\"/><path d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H6.556z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});