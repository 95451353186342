define("ember-svg-jar/inlined/activity-icon-unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zM7.385 7.608H22.53c.98 0 1.782.801 1.782 1.781v5.705a6.11 6.11 0 11-8.591 7.214H7.385c-.98 0-1.782-.802-1.782-1.782V9.389c0-.98.802-1.781 1.782-1.781zm7.572 8.686l7.35-4.9V9.612l-7.35 4.9-7.35-4.9v1.782l7.35 4.9zm2.343 4.264a4.276 4.276 0 108.552 0 4.276 4.276 0 00-8.552 0zm1.499-1.111v2.221h5.554v-2.221h-5.554z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});