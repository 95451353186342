define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.613 3.215a1 1 0 00-1.24 0L1 14.427l1.24 1.58L4 14.628V27.01a2 2 0 002 2h20.004a2 2 0 002-2V14.637l1.761 1.37 1.24-1.57L16.613 3.215zm1.39 23.795h-4v-8.002h4v8.002zm2 0v-8.002a2 2 0 00-2-2h-4a2 2 0 00-2.001 2v8.002H6V13.067l10.002-7.791 10.001 7.801V27.01h-6z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});