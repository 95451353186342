define("ember-svg-jar/inlined/set-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#DDE2E7\" d=\"M60.22 23.375h-1.968v-5.906C58.252 10.594 52.657 5 45.783 5s-12.468 5.595-12.468 12.469v5.906h-1.97a3.939 3.939 0 00-3.937 3.938v15.75A3.939 3.939 0 0031.346 47H60.22a3.939 3.939 0 003.937-3.938v-15.75a3.939 3.939 0 00-3.937-3.937zm-8.53 0H39.877v-5.906a5.913 5.913 0 015.906-5.907 5.913 5.913 0 015.907 5.907v5.906z\"/><rect width=\"76\" height=\"19\" x=\"7.5\" y=\"36.5\" fill=\"#fff\" stroke=\"#DDE2E7\" rx=\"3.5\"/><circle cx=\"18.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"27.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"36.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"45.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"54.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"63.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/><circle cx=\"72.5\" cy=\"46.5\" r=\"2.5\" fill=\"#6C767F\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "92",
      "height": "62",
      "fill": "none",
      "viewBox": "0 0 92 62"
    }
  };
});