define("ember-svg-jar/inlined/chart-types/spline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#000\" stroke-width=\"3\" stroke-linecap=\"square\" stroke-linejoin=\"round\" d=\"M10.2 34.2c5.1-2.9 4.8-9.7 9.4-9.7 6.4 0 4.5 11.4 10 11.4s6.2-9.7 10.8-9.7c7.1 0 8.2-1.8 8.9-4.5\"/><path fill=\"none\" class=\"chart-type-color-stroke\" d=\"M49.3 30s-4.8 4.3-9.4 4.3c-6.7 0-4.3-8.2-10-10.1-4.6-1.5-6.2 5.2-9.5 9.7-3.4 4.5-10.1 4.5-10.1 4.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});