define("ember-svg-jar/inlined/compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16 4a12 12 0 110 24 12 12 0 010-24zm0-2a14 14 0 100 28 14 14 0 000-28zm6.999 8.41L21.589 9l-4.3 4.3a3 3 0 00-4 4L9 21.59 10.407 23l4.3-4.3a3 3 0 004-4l4.29-4.29zm-6 5.59a1 1 0 11-2 0 1 1 0 012 0zm.503-8.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
});