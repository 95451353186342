define("ember-svg-jar/inlined/right-angle-bracket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.151 14.858L10.761.472a1.62 1.62 0 00-2.287 0 1.612 1.612 0 000 2.283L21.722 16 8.475 29.243a1.614 1.614 0 000 2.285c.632.63 1.656.63 2.288 0l14.39-14.386a1.63 1.63 0 00-.002-2.284z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});