define("ember-svg-jar/inlined/ibm/rich-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 6H14v2h14V6zm0 6H14v2h14v-2zm0 6H7v2h21v-2zm0 6H7v2h21v-2zM9.64 14h1.86L7.735 5.364h-1.97L2 14h1.86l.691-1.727H8.95L9.639 14zm-4.398-3.454l1.508-3.77 1.508 3.77H5.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});