define("ember-scrollable/util/css", ["exports", "@ember/utils", "@ember/template"], function (_exports, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.styleify = styleify;
  function styleify(obj) {
    if ((0, _utils.isEmpty)(obj)) {
      return (0, _template.htmlSafe)('');
    }
    const styles = Object.keys(obj).reduce((styleString, key) => {
      const styleValue = obj[key];
      if (!(0, _utils.isEmpty)(styleValue)) {
        styleString += `${key}: ${styleValue}; `;
      }
      return styleString;
    }, '');
    return (0, _template.htmlSafe)(styles);
  }
});