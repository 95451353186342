define("ember-svg-jar/inlined/font-awesome/exclamation-triangle-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.64 26.445c1.025 1.778-.262 4-2.31 4H2.67c-2.052 0-3.334-2.226-2.31-4L13.69 3.332c1.026-1.778 3.596-1.775 4.62 0l13.33 23.113zM16 21.667a2.555 2.555 0 100 5.11 2.555 2.555 0 000-5.11zm-2.426-9.186l.412 7.555c.02.354.311.63.665.63h2.698a.667.667 0 00.665-.63l.412-7.555a.667.667 0 00-.665-.703h-3.522a.667.667 0 00-.665.703z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});