define("ember-svg-jar/inlined/ibm/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 16A14 14 0 1116 2a14.016 14.016 0 0114 14zM4 16A12 12 0 1016 4 12.014 12.014 0 004 16z\"/><path d=\"M22 12.5c0 .17-.043.337-.126.486l-5 9a1 1 0 01-1.748 0l-5-9A1 1 0 0111 11.5h10a1 1 0 011 1zm-6 6.94l3.3-5.94h-6.6l3.3 5.94z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});