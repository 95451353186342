define("ember-svg-jar/inlined/font-awesome/spinner-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 3a3 3 0 11-6 0 3 3 0 016 0zm-3 23a3 3 0 100 6 3 3 0 000-6zm13-13a3 3 0 100 6 3 3 0 000-6zM6 16a3 3 0 10-6 0 3 3 0 006 0zm.808 6.192a3 3 0 100 6 3 3 0 000-6zm18.384 0a3 3 0 100 6 3 3 0 000-6zM6.808 3.808a3 3 0 100 6 3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});