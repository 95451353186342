define("ember-svg-jar/inlined/caret-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 8h25.73c1.78 0 2.67 2.15 1.41 3.41L17.28 24.28c-.78.78-2.05.78-2.83 0L1.59 11.41C.33 10.15 1.22 8 3 8z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});