define("ember-svg-jar/inlined/two-column-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 4.667C2 3.747 2.727 3 3.623 3h24.34c.897 0 1.623.746 1.623 1.667v21.666c0 .92-.726 1.667-1.623 1.667H3.623C2.727 28 2 27.254 2 26.333V4.667zm14.604.402h10.913v20.862H16.605V5.069zm-1.622 0H4.069v20.862h10.913V5.069z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});