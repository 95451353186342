define("ember-svg-jar/inlined/two-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 4.667C2 3.747 2.737 3 3.647 3h24.706C29.263 3 30 3.746 30 4.667v21.666c0 .92-.737 1.667-1.647 1.667H3.647C2.737 28 2 27.254 2 26.333V4.667zm14.823 1.666h9.883v18.334h-9.883V6.333zm-1.646 0H5.294v18.334h9.883V6.333z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});