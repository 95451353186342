define("ember-svg-jar/inlined/activity-icon-open-data-room-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.875 8.025l3.85 3.85h-3.85v-3.85z\"/><path fill-rule=\"evenodd\" d=\"M9.447 23.981c.263.176.572.27.888.27a1.605 1.605 0 001.599-1.6 1.6 1.6 0 10-2.487 1.33zm.889-2.285a.699.699 0 00.584 1.074.69.69 0 00.375-.11.963.963 0 11-.96-.964z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm7.169-20.606l-4.813-4.813A.624.624 0 0018 6.384l-.015-.002a.625.625 0 00-.11-.007H11A1.38 1.38 0 009.625 7.75V19H14.5a1 1 0 011 1v5.625H22a1.38 1.38 0 001.375-1.375V11.875a.625.625 0 00-.206-.481zm-12.834 9.022c1.666 0 2.918 1.2 3.823 2.243l.016.022-.11.11c-.475.509-1 .97-1.565 1.375a3.808 3.808 0 01-2.163.728c-1.652 0-2.6-.972-3.7-2.098l-.138-.141.02-.02c.559-.615 1.19-1.16 1.88-1.623a3.542 3.542 0 011.937-.596z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});