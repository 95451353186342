define("ember-svg-jar/inlined/document-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 25l-1.414-1.414L26 26.172V18h-2v8.172l-2.586-2.586L20 25l5 5 5-5z\"/><path d=\"M18 28H8V4h8v6a2.006 2.006 0 002 2h6v3h2v-5a.91.91 0 00-.3-.7l-7-7A.909.909 0 0018 2H8a2.006 2.006 0 00-2 2v24a2.006 2.006 0 002 2h10zm0-23.6l5.6 5.6H18z\"/><title>document--download</title>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "aria-hidden": "true",
      "style": "will-change:transform",
      "fill": "currentColor"
    }
  };
});