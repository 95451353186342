define("ember-svg-jar/inlined/font-awesome/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.466 13.903l1.387 1.388a1.494 1.494 0 010 2.118L16.71 29.56a1.494 1.494 0 01-2.118 0L2.44 17.41a1.494 1.494 0 010-2.118l1.387-1.388a1.502 1.502 0 012.144.025l7.175 7.531V3.497c0-.831.669-1.5 1.5-1.5h2c.831 0 1.5.669 1.5 1.5v17.962l7.175-7.53a1.49 1.49 0 012.144-.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});