define("ember-svg-jar/inlined/chart-types/waterfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M7 14.354h3.034V7.898H7v6.457zM12.102 14.354h3.034V9.398h-3.034v4.957zM17.16 6v8.354h3.034V6h-3.035zM22.217 8.722v5.663h3.034V8.722h-3.034z\" fill=\"#000\"/><path d=\"M7 22.877h3.034V18.65H7v4.227zM12.102 27h3.034v-8.35h-3.034V27zM17.16 18.645v4.232h3.034v-4.232h-3.035zM22.217 18.645v5.912h3.034v-5.912h-3.034z\" fill=\"#D4D4D8\"/><path fill=\"#AAA\" d=\"M7.077 15.759h18.141v1.649H7.077z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});