define("ember-svg-jar/inlined/font-awesome/angle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.472 17.063l-8.5 8.5a1.494 1.494 0 01-2.119 0l-1.412-1.413a1.494 1.494 0 010-2.119l6.025-6.025-6.025-6.025a1.494 1.494 0 010-2.119l1.406-1.425a1.494 1.494 0 012.119 0l8.5 8.5c.593.588.593 1.538.006 2.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});