define("ember-svg-jar/inlined/navigation/inbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.071 10.378c.619.475.929 1.101.929 1.879v13.305c0 .678-.257 1.252-.765 1.727a2.55 2.55 0 01-.85.534c-.32.122-.664.182-1.01.177H4.625c-.73 0-1.348-.239-1.86-.711a2.351 2.351 0 01-.575-.79 2.204 2.204 0 01-.19-.937V12.207c0-.745.327-1.372.985-1.879 1.458-1.117 3.92-2.946 7.383-5.485l.546-.455c.95-.713 1.696-1.235 2.242-1.576C14.18 2.271 15.126 2 16 2c.875 0 1.823.27 2.845.812.583.338 1.33.863 2.24 1.576l.548.455c2.5 1.82 4.98 3.665 7.438 5.535zm-1.696 14.879V12.359c0-.068-.037-.135-.11-.202a598.758 598.758 0 00-7.273-5.434l-.546-.407a16.958 16.958 0 00-1.696-1.218c-.693-.44-1.276-.66-1.75-.66-.473 0-1.057.22-1.75.66-.401.239-.966.644-1.696 1.218l-7.819 5.841c-.072.033-.11.1-.11.202v12.898c0 .069.038.136.11.203.072.067.145.102.22.102h22.091c.075 0 .147-.035.22-.102.072-.067.109-.134.109-.203zm-2.571-10.46l.821.913a.6.6 0 01.062.687.573.573 0 01-.172.178l-4.428 3.35c-.95.712-1.697 1.235-2.242 1.575-1.022.541-1.97.812-2.845.812-.875 0-1.822-.27-2.844-.812a21.158 21.158 0 01-2.24-1.575l-4.433-3.35a.573.573 0 01-.171-.179.6.6 0 01.063-.684l.822-.916a.589.589 0 01.191-.158.636.636 0 01.245-.07.72.72 0 01.492.126l4.429 3.302c.73.574 1.295.982 1.696 1.218.693.44 1.277.66 1.75.66.474 0 1.057-.22 1.75-.66.401-.238.966-.644 1.697-1.218l4.428-3.302a.72.72 0 01.49-.125.606.606 0 01.439.227z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});