define("ember-svg-jar/inlined/font-awesome/angle-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.44 14.94l8.5-8.5a1.494 1.494 0 012.118 0l1.413 1.413a1.494 1.494 0 010 2.119l-6.025 6.025 6.025 6.025a1.494 1.494 0 010 2.119l-1.406 1.425a1.494 1.494 0 01-2.119 0l-8.5-8.5a1.496 1.496 0 01-.006-2.125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});