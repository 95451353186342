define("ember-svg-jar/inlined/ibm/document-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 19H14.83l2.58-2.59L16 15l-5 5 5 5 1.41-1.41L14.83 21H28v-2z\"/><path d=\"M24 14v-4a1 1 0 00-.29-.71l-7-7A1 1 0 0016 2H6a2 2 0 00-2 2v24a2 2 0 002 2h16a2 2 0 002-2v-2h-2v2H6V4h8v6a2 2 0 002 2h6v2zm-8-4V4.41L21.59 10z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});