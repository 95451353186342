define("ember-svg-jar/inlined/variance-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.643 19.75h-1.857v7.5h1.857v-7.5zM8.643 21.25H10.5v6H8.643v-6zM16.071 22.75v4.5h-1.857v-4.5h1.857z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30 8.5a7.515 7.515 0 01-3.714 6.483V29.17c0 1.01-.778 1.829-1.737 1.829H5.736C4.777 31 4 30.181 4 29.17V4.705c0-1.01.777-1.829 1.736-1.829h11.936A7.455 7.455 0 0122.529 1c1.982 0 3.882.79 5.283 2.197A7.516 7.516 0 0130 8.5zM16.212 4.634a7.522 7.522 0 002.457 10.236l-.487.638-5.065-4.27-5.065 5.337 1.013 1.067 4.052-4.269 5.065 4.27 1.74-2.147c.824.32 1.707.494 2.607.504a7.44 7.44 0 002.02-.28v13.523H5.736V4.633h10.476zm6.04-.683l-1.837 2.554c-.15.267.066.582.402.582h3.425c.335 0 .555-.315.404-.582l-1.84-2.553c-.113-.205-.439-.206-.553 0zm.556 9.097l1.837-2.553c.15-.267-.067-.583-.402-.583h-3.426c-.336 0-.554.316-.404.583l1.84 2.554c.114.204.441.203.555-.001z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});