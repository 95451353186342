define("ember-svg-jar/inlined/ibm/list-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 8H16v2h14V8zM6 10.59L3.41 8 2 9.41l4 4 8-8L12.59 4 6 10.59zM30 22H16v2h14v-2zM6 24.59L3.41 22 2 23.41l4 4 8-8L12.59 18 6 24.59z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});