define("ember-svg-jar/inlined/database", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.001 3c-2.001 0-3.845.178-5.533.533-1.686.351-3.02.822-4 1.403C5.49 5.52 5 6.166 5 6.876v.25c0 .707.49 1.354 1.467 1.937.98.584 2.314 1.052 4 1.403 1.688.357 3.532.535 5.533.535 1.999 0 3.843-.178 5.531-.532 1.686-.354 3.02-.822 4-1.406C26.51 8.48 27 7.833 27 7.126v-.25c0-.71-.49-1.355-1.467-1.938-.98-.583-2.314-1.054-4-1.405A26.946 26.946 0 0016 3zM27 11.437c-1.124.835-2.707 1.48-4.75 1.94-1.915.416-3.998.623-6.248.623-2.252 0-4.332-.207-6.252-.623-2.04-.46-3.623-1.105-4.75-1.94v1.625c0 .709.491 1.354 1.469 1.94.98.583 2.314 1.05 4 1.405 1.688.355 3.532.532 5.533.532 1.999 0 3.843-.177 5.531-.532 1.686-.354 3.02-.822 4-1.405C26.51 14.417 27 13.77 27 13.063v-1.627zm0 6.002c-1.124.834-2.707 1.476-4.75 1.937A29.27 29.27 0 0116 20c-2.252 0-4.332-.21-6.252-.625-2.04-.458-3.623-1.103-4.75-1.938v1.69c0 .706.491 1.353 1.469 1.936.98.584 2.314 1.051 4 1.403C12.156 22.823 14 23 16 23c1.999 0 3.843-.177 5.531-.531 1.686-.355 3.02-.822 4-1.406.977-.583 1.467-1.23 1.467-1.937v-1.689zm0 5.998c-1.124.835-2.707 1.48-4.75 1.94-1.916.415-4 .623-6.25.623-2.252 0-4.332-.21-6.252-.625-2.04-.46-3.623-1.105-4.75-1.94v1.689c0 .709.491 1.354 1.469 1.937.98.583 2.314 1.053 4 1.405C12.156 28.823 14 29 16 29c1.999 0 3.843-.177 5.531-.531 1.686-.352 3.02-.823 4-1.404.977-.585 1.467-1.23 1.467-1.94v-1.688zM16 0c2.126 0 4.147.187 6.062.564 2.334.458 4.168 1.164 5.502 2.124C29.188 3.813 30 5.208 30 6.875v18.25c0 1.666-.812 3.062-2.437 4.187-1.334.96-3.168 1.666-5.502 2.124-1.915.377-3.938.564-6.062.564a31.544 31.544 0 01-6.06-.564c-2.334-.458-4.168-1.164-5.502-2.124C2.812 28.187 2 26.792 2 25.125V6.875c0-1.666.812-3.062 2.437-4.187C5.77 1.728 7.605 1.022 9.938.564A31.533 31.533 0 0115.998 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});