define("ember-svg-jar/inlined/activity-icon-open-data-room-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.339 23.334a1.807 1.807 0 111.807-1.807 1.814 1.814 0 01-1.807 1.807zm0-2.887A.79.79 0 008 21.66a.78.78 0 00.425-.124 1.087 1.087 0 11-1.084-1.09z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M32.001 16c0 8.837-7.164 16.001-16 16.001-8.837 0-16.001-7.164-16.001-16C0 7.163 7.164 0 16 0c8.837 0 16.001 7.164 16.001 16zM14.768 8.3H8.295a1.997 1.997 0 00-1.992 1.992v7.185h6.094c.419 0 .758.34.758.758v5.003h12.069a1.997 1.997 0 001.991-1.992v-8.713a1.997 1.997 0 00-1.991-1.992h-8.465l-1.991-2.24zM11.66 21.536C10.637 20.356 9.222 19 7.34 19c-.78.007-1.54.24-2.19.674a11.69 11.69 0 00-2.125 1.834l-.022.022.151.156.004.005c1.243 1.272 2.316 2.37 4.183 2.37a4.303 4.303 0 002.445-.823 11.928 11.928 0 001.768-1.554l.124-.124-.017-.025z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 33 32"
    }
  };
});