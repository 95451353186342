define("ember-svg-jar/inlined/x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.245 29.528a2.8 2.8 0 003.534-.346L16 19.962l9.221 9.22a2.8 2.8 0 003.96-3.96l-9.222-9.22L29.18 6.78a2.8 2.8 0 00-3.962-3.96l-9.22 9.22L6.78 2.82a2.8 2.8 0 00-3.96 3.96L12.04 16l-9.222 9.222a2.8 2.8 0 00.428 4.305z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});