define("ember-svg-jar/inlined/ibm/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 2a8.997 8.997 0 00-8.612 11.612L2 24v6h6l10.388-10.388A8.999 8.999 0 1021 2zm0 16a7.01 7.01 0 01-2.032-.302l-1.147-.348-4.028 4.028L12.414 20 11 21.414l1.379 1.379-1.586 1.586L9.414 23 8 24.414l1.379 1.379L7.172 28H4v-3.172l10.65-10.65-.348-1.146A7 7 0 1121 18z\"/><path d=\"M22 12a2 2 0 100-4 2 2 0 000 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});