define("ember-svg-jar/inlined/airtable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_3_458)\"><path d=\"M7.175 1.173l-5.98 2.481a.407.407 0 000 .75L7.205 6.8c.533.202 1.109.202 1.642 0l6.022-2.395a.404.404 0 000-.75L8.89 1.174a2.21 2.21 0 00-1.714 0z\" fill=\"#FCB400\"/><path d=\"M8.572 8.097v5.973c0 .288.289.49.548.375l6.714-2.597c.158-.058.245-.202.245-.375V5.501c0-.289-.289-.49-.548-.375L8.817 7.722c-.158.058-.245.202-.245.375z\" fill=\"#18BFFF\"/><path d=\"M0 5.515v5.612c0 .303.346.49.605.36l4.193-2.02.201-.1L.13 5.241A.382.382 0 000 5.515z\" fill=\"#F82B60\"/><path d=\"M6.973 7.68L.591 5.14a.441.441 0 00-.36.03 1.33 1.33 0 00-.116.071L5 9.367l1.989-.952c.33-.159.302-.62-.015-.736z\" fill=\"#BA1E45\"/></g><defs><clipPath id=\"clip0_3_458\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});