define("ember-svg-jar/inlined/ibm/text-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.506 27H7V5h10.573c1.246 0 2.467.35 3.517 1.01a6.452 6.452 0 012.41 2.713 6.317 6.317 0 01.543 3.561 6.364 6.364 0 01-1.495 3.288 6.411 6.411 0 012.196 3.233 6.31 6.31 0 01-.106 3.885 6.428 6.428 0 01-2.37 3.112A6.609 6.609 0 0118.507 27zm-7.774-3.667h7.749a2.82 2.82 0 001.979-.805 2.709 2.709 0 000-3.89 2.842 2.842 0 00-1.979-.805h-7.75v5.5zm0-9.166h6.84c.368 0 .732-.072 1.072-.21a2.785 2.785 0 001.515-1.488 2.707 2.707 0 00-.607-2.997 2.802 2.802 0 00-.908-.596 2.842 2.842 0 00-1.071-.21h-6.841v5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});