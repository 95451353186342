define("ember-scrollable/templates/components/ember-scrollbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='tse-scrollbar {{if @horizontal 'horizontal' 'vertical'}}'
    ...attributes
    {{did-insert this.elementInserted}}
  >
    <div
      class='drag-handle {{if @showHandle 'visible'}}'
      style={{this.handleStyles}}
      {{on 'mousedown' this.startDrag}}
    ></div>
  </div>
  */
  {
    "id": "GuVB6Kwm",
    "block": "[[[11,0],[16,0,[29,[\"tse-scrollbar \",[52,[30,1],\"horizontal\",\"vertical\"]]]],[17,2],[4,[38,1],[[30,0,[\"elementInserted\"]]],null],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"drag-handle \",[52,[30,3],\"visible\"]]]],[16,5,[30,0,[\"handleStyles\"]]],[4,[38,2],[\"mousedown\",[30,0,[\"startDrag\"]]],null],[12],[13],[1,\"\\n\"],[13]],[\"@horizontal\",\"&attrs\",\"@showHandle\"],false,[\"if\",\"did-insert\",\"on\"]]",
    "moduleName": "ember-scrollable/templates/components/ember-scrollbar.hbs",
    "isStrictMode": false
  });
});