define("ember-svg-jar/inlined/font-awesome/eye-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 10a6.161 6.161 0 00-1.736.278c.26.457.399.974.403 1.5a3.11 3.11 0 01-3.111 3.11 3.078 3.078 0 01-1.5-.401A6.206 6.206 0 1016 10zm15.807 5.411C28.794 9.533 22.829 5.556 16 5.556c-6.83 0-12.796 3.98-15.807 9.856a1.797 1.797 0 000 1.621C3.206 22.912 9.171 26.89 16 26.89c6.83 0 12.796-3.98 15.807-9.856a1.797 1.797 0 000-1.622zM16 24.222c-5.48 0-10.505-3.055-13.218-8 2.713-4.944 7.737-8 13.218-8s10.505 3.056 13.218 8c-2.712 4.945-7.737 8-13.218 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});