define("ember-svg-jar/inlined/activity-icon-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm.003-5.448l1.302.748 2.565-4.5h3.63a3 3 0 003-3v-9a3 3 0 00-3-3h-15a3 3 0 00-3 3v9a3 3 0 003 3h6.595L16 26.557l.003-.005zM9.5 11.5a.5.5 0 00-.5.5v.45a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V12a.5.5 0 00-.5-.5h-11zm0 2.95a.5.5 0 00-.5.5v.45a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.45a.5.5 0 00-.5-.5h-11zM9 17.9a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v.45a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});