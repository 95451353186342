define("ember-svg-jar/inlined/ibm/text-italic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 7.444V5H11.111v2.444h6.282l-5.34 17.112H5V27h15.889v-2.444h-6.282l5.34-17.112H27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});