define("ember-svg-jar/inlined/ibm/h3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.76 18.88c.8 0 1.387-.16 1.76-.48.387-.333.58-.753.58-1.26V17c0-.573-.187-1.02-.56-1.34-.36-.333-.86-.5-1.5-.5-.613 0-1.16.147-1.64.44-.467.293-.873.707-1.22 1.24l-1.68-1.52c.24-.32.5-.62.78-.9a4.24 4.24 0 011-.74c.373-.213.793-.38 1.26-.5.467-.12.993-.18 1.58-.18a6.63 6.63 0 011.92.26c.587.16 1.087.4 1.5.72.427.32.753.707.98 1.16.227.44.34.94.34 1.5 0 .453-.073.86-.22 1.22a2.72 2.72 0 01-.58.94 3.01 3.01 0 01-.86.66c-.32.16-.66.28-1.02.36v.12c.4.08.78.207 1.14.38.36.173.667.407.92.7.267.28.473.613.62 1 .16.387.24.827.24 1.32a3.71 3.71 0 01-.38 1.68c-.24.507-.587.94-1.04 1.3a5.04 5.04 0 01-1.62.84c-.627.187-1.327.28-2.1.28-.68 0-1.28-.073-1.8-.22-.52-.133-.98-.32-1.38-.56a5.186 5.186 0 01-1.02-.82 6.599 6.599 0 01-.76-1l1.94-1.48c.16.28.333.54.52.78.187.227.4.427.64.6s.513.307.82.4c.307.093.653.14 1.04.14.773 0 1.367-.187 1.78-.56.413-.373.62-.887.62-1.54v-.14c0-.64-.227-1.127-.68-1.46-.44-.333-1.08-.5-1.92-.5h-1.38v-2.2h1.38zM13.95 5v10.54L5 15.5V5H2v22h3v-8.54l8.95.04V27H17V5h-3.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});