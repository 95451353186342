define("ember-svg-jar/inlined/data-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 28H6a2.002 2.002 0 01-2-2V11a2.002 2.002 0 012-2h5.666c.433 0 .854.141 1.201.4l3.466 2.6H26a2.002 2.002 0 012 2v12a2.002 2.002 0 01-2 2zM11.666 11H5.998L6 26h20V14H15.666l-4-3zM28 9H17.666l-4-3H6V4h7.666c.433 0 .854.141 1.201.4L18.333 7H28v2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});