define("ember-svg-jar/inlined/chart-types/areaspline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"chart-type-color-fill\" d=\"M9.3 40.9l10.9-9.6 10-12.2 9.9 3.6 10.7-3.5v21.6l-41.5.1z\"/><path d=\"M20.1 34.5l10-6.7 9.9 3.5 10.7-6.8v16.4H9.2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});