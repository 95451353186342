define("ember-svg-jar/inlined/ibm/content-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 21a2 2 0 100-4 2 2 0 000 4z\"/><path d=\"M23.777 18.479A8.64 8.64 0 0016 13a8.64 8.64 0 00-7.777 5.479L8 19l.223.521A8.64 8.64 0 0016 25a8.64 8.64 0 007.777-5.479L24 19l-.223-.521zM16 23a4 4 0 110-8 4 4 0 010 8z\"/><path d=\"M27 3H5a2 2 0 00-2 2v22a2 2 0 002 2h22a2 2 0 002-2V5a2 2 0 00-2-2zM5 5h22v4H5V5zm0 22V11h22v16H5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});