define("ember-svg-jar/inlined/company-logo-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.88 7.771H8.96v4.117h1.92V7.771zm0 6.171H8.96v4.116h1.92v-4.116zm5.76-6.17h-1.92v4.116h1.92V7.771zm0 6.17h-1.92v4.116h1.92v-4.116zm-5.76 6.172H8.96v4.115h1.92v-4.117.002zm5.76 0h-1.92v4.115h1.92v-4.117.002z\"/><path d=\"M30.08 13.942a2.13 2.13 0 00-.563-1.454 1.856 1.856 0 00-1.357-.602H22.4V3.658a2.14 2.14 0 00-.562-1.456A1.856 1.856 0 0020.48 1.6H5.12a1.856 1.856 0 00-1.358.603A2.134 2.134 0 003.2 3.658V30.4h26.88V13.942zM5.12 3.658h15.36v24.684H5.12V3.658zM22.4 28.342v-14.4h5.76v14.4H22.4z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});