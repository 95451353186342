define("ember-svg-jar/inlined/ibm/color-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"10\" cy=\"12\" r=\"2\"/><circle cx=\"16\" cy=\"9\" r=\"2\"/><circle cx=\"22\" cy=\"12\" r=\"2\"/><circle cx=\"23\" cy=\"18\" r=\"2\"/><circle cx=\"19\" cy=\"23\" r=\"2\"/><path d=\"M16.54 2A14 14 0 002 16a4.82 4.82 0 006.09 4.65l1.12-.31a3 3 0 013.79 2.9V27a3 3 0 003 3 14 14 0 0014-14.54A14.05 14.05 0 0016.54 2zm8.11 22.31A11.93 11.93 0 0116 28a1 1 0 01-1-1v-3.76a5 5 0 00-5-5 5.07 5.07 0 00-1.33.18l-1.12.31A2.82 2.82 0 014 16 12 12 0 0116.47 4 12.18 12.18 0 0128 15.53a11.89 11.89 0 01-3.35 8.79z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});