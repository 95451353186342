define("ember-svg-jar/inlined/zapier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#FF4A00\" d=\"M23.75 19.008c0 1.412-.26 2.763-.73 4.012-1.247.47-2.6.729-4.012.73h-.016a11.389 11.389 0 01-4.012-.73c-.47-1.247-.73-2.6-.73-4.012v-.016c0-1.412.26-2.764.73-4.011 1.247-.471 2.6-.73 4.012-.73h.016c1.412 0 2.765.259 4.012.73.47 1.247.73 2.6.73 4.01v.017zm13.986-3.175h-11.09l7.841-7.842a19.08 19.08 0 00-2.052-2.427 19.15 19.15 0 00-2.427-2.051l-7.842 7.842V.265A19.09 19.09 0 0019.01 0h-.02c-1.076 0-2.13.092-3.157.264v11.091L7.991 3.513a18.973 18.973 0 00-2.425 2.052l-.004.003a19.12 19.12 0 00-2.05 2.423l7.843 7.842H.265S0 17.916 0 18.993v.014c0 1.077.091 2.132.264 3.16h11.091l-7.843 7.842a19.137 19.137 0 004.479 4.479l7.842-7.843v11.091c1.027.172 2.08.263 3.153.264h.027a19.165 19.165 0 003.153-.264V26.645l7.843 7.843a19.152 19.152 0 002.426-2.052l.001-.001a19.19 19.19 0 002.051-2.426l-7.843-7.842h11.092c.173-1.026.263-2.079.264-3.153v-.028a19.111 19.111 0 00-.264-3.153z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 38 38"
    }
  };
});