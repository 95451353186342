define("ember-svg-jar/inlined/font-awesome/calendar-alt-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.25 18h-2.5a.752.752 0 01-.75-.75v-2.5c0-.412.338-.75.75-.75h2.5c.412 0 .75.338.75.75v2.5c0 .413-.338.75-.75.75zm6.75-.75v-2.5a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75v2.5c0 .413.338.75.75.75h2.5c.413 0 .75-.337.75-.75zm6 0v-2.5a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75v2.5c0 .413.337.75.75.75h2.5c.413 0 .75-.337.75-.75zm-6 6v-2.5a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75v2.5c0 .413.338.75.75.75h2.5c.413 0 .75-.337.75-.75zm-6 0v-2.5a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75v2.5c0 .413.338.75.75.75h2.5c.412 0 .75-.337.75-.75zm12 0v-2.5a.752.752 0 00-.75-.75h-2.5a.752.752 0 00-.75.75v2.5c0 .413.337.75.75.75h2.5c.413 0 .75-.337.75-.75zM30 7v22a3 3 0 01-3 3H5a3 3 0 01-3-3V7a3 3 0 013-3h3V.75c0-.412.338-.75.75-.75h2.5c.412 0 .75.338.75.75V4h8V.75c0-.412.337-.75.75-.75h2.5c.413 0 .75.338.75.75V4h3a3 3 0 013 3zm-3 21.625V10H5v18.625c0 .206.169.375.375.375h21.25a.376.376 0 00.375-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});