define("ember-svg-jar/inlined/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.59 22L15 16.41V7h2v8.58l5 5.01L20.59 22z\"/><path d=\"M16 2A13.94 13.94 0 006 6.23V2H4v8h8V8H7.08A12 12 0 114 16H2A14 14 0 1016 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});