define("ember-svg-jar/inlined/font-awesome/bell-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 32a4 4 0 003.997-4h-7.996A4 4 0 0016 32zm13.461-9.357c-1.207-1.297-3.467-3.25-3.467-9.643 0-4.856-3.405-8.744-7.996-9.697V2a2 2 0 10-3.997 0v1.303C9.409 4.256 6.004 8.143 6.004 13c0 6.394-2.259 8.346-3.466 9.643A1.953 1.953 0 001.998 24c.007 1.025.812 2 2.007 2h23.987c1.195 0 2-.975 2.006-2a1.952 1.952 0 00-.538-1.357z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});