define("ember-svg-jar/inlined/written-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.753 1.5h21.042c.969 0 1.753.785 1.753 1.753v25.494c0 .968-.784 1.753-1.753 1.753H5.753A1.753 1.753 0 014 28.747V3.253C4 2.285 4.785 1.5 5.753 1.5zm21.043 1.686H5.753v25.63h21.043V3.187z\"/><path d=\"M9.26 12.555h14.027v2.046H9.26v-2.046zm0 3.37h14.027v2.045H9.26v-2.046zm0 3.369h5.259v2.045h-5.26v-2.045zM13 7.5a2 2 0 11-4 0 2 2 0 014 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});