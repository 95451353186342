define("ember-svg-jar/inlined/ibm/quotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 15H6.11A9 9 0 0110 8.86l1.79-1.2L10.69 6 8.9 7.2A11 11 0 004 16.35V23a2 2 0 002 2h6a2 2 0 002-2v-6a2 2 0 00-2-2zm14 0h-5.89A9 9 0 0124 8.86l1.79-1.2L24.7 6l-1.8 1.2a11 11 0 00-4.9 9.15V23a2 2 0 002 2h6a2 2 0 002-2v-6a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});