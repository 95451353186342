define("ember-svg-jar/inlined/font-awesome/lightbulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 5c-3.309 0-6 2.691-6 6a1 1 0 102 0c0-2.206 1.795-4 4-4a1 1 0 100-2zm-4.996 23.698c0 .197.058.389.167.553l1.532 2.302a1 1 0 00.833.446h4.928a.999.999 0 00.832-.446l1.532-2.302a1.01 1.01 0 00.168-.553L20.999 26H11l.003 2.7zM16 0C9.607 0 5 5.186 5 11c0 2.773 1.028 5.303 2.723 7.236 1.04 1.187 2.67 3.675 3.276 5.76V24h3v-.008c0-.298-.045-.594-.135-.879-.349-1.113-1.426-4.048-3.885-6.854A7.975 7.975 0 018.003 11C7.991 6.397 11.733 3 16 3c4.411 0 8 3.589 8 8a7.976 7.976 0 01-1.978 5.259c-2.444 2.788-3.526 5.717-3.881 6.841-.092.29-.139.59-.14.894V24h3v-.003c.606-2.086 2.237-4.574 3.277-5.76A10.944 10.944 0 0027 11c0-6.075-4.925-11-11-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});