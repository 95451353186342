define("ember-svg-jar/inlined/user-provided-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.528 22.295v3.842a11.81 11.81 0 002.196 1.6 11.606 11.606 0 005.853 1.535 11.606 11.606 0 005.854-1.535 11.775 11.775 0 002.707-2.101l-.472-3.207a4.27 4.27 0 00-.914-1.64c-.535-.581-1.213-.995-2.03-1.24a5.73 5.73 0 00.897-3.117 5.676 5.676 0 00-.446-2.254 5.812 5.812 0 00-1.309-1.912 6.008 6.008 0 00-1.967-1.271 6.147 6.147 0 00-2.32-.434 6.13 6.13 0 00-3.026.768 5.911 5.911 0 00-2.216 2.144c-.535.902-.802 1.902-.802 3.005a5.652 5.652 0 00.897 3.072c-.817.246-1.495.658-2.028 1.238-.39.431-.68.933-.874 1.507zm10.717-8.455c.74.717 1.109 1.581 1.109 2.592 0 1.011-.37 1.873-1.107 2.59a3.699 3.699 0 01-2.668 1.079c-1.041 0-1.93-.36-2.668-1.077-.74-.72-1.11-1.583-1.11-2.592 0-1.01.37-1.875 1.108-2.592a3.697 3.697 0 012.668-1.077c1.04 0 1.93.36 2.668 1.077zM6.343 26.522a9.883 9.883 0 01-2.808-1.561v-1.283c0-.46.14-.88.424-1.261a1.99 1.99 0 011.133-.757l1.181-.322c.976.643 2.078.963 3.304.963a5.877 5.877 0 003.306-.963l1.178.322c.46.113.862.381 1.136.757.282.38.424.801.424 1.261v1.283a9.859 9.859 0 01-2.809 1.559 9.498 9.498 0 01-3.233.551 9.492 9.492 0 01-3.236-.55z\"/><path d=\"M23.467 13.28H21.6V16h-2.8v1.814h2.8v2.72h1.867v-2.72h2.8V16h-2.8v-2.72z\"/><path d=\"M20.968 1.318l8.223 8.318c.54.546.81 1.228.81 1.91V28c0 1.5-1.214 2.5-2.697 2.5H18.8v-2H28V11.329L19.215 2.5H7.5v6.654H5.733V3.227C5.733 1.727 6.517.5 8 .5h11.215c.674 0 1.348.273 1.753.818z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});