define("ember-svg-jar/inlined/cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30.91 1a.715.715 0 01.626.713v18.65a.714.714 0 01-.71.718H1.012a.715.715 0 01-.711-.718V1.713a.71.71 0 01.378-.623A.7.7 0 01.757 1H30.91zM15.918 15.503a4.462 4.462 0 100-8.925 4.462 4.462 0 000 8.925zM4.762 13.27a2.231 2.231 0 100-4.462 2.231 2.231 0 000 4.463zm24.543-2.23a2.231 2.231 0 11-4.462 0 2.231 2.231 0 014.462 0z\"/><path d=\"M.3 24.415a1.104 1.104 0 011.103-1.103h29.03a1.104 1.104 0 011.103 1.103v.024a1.103 1.103 0 01-1.103 1.104H1.403A1.104 1.104 0 01.3 24.439v-.024zm1.103 3.36A1.103 1.103 0 00.3 28.877v.024a1.103 1.103 0 001.103 1.103h29.03a1.103 1.103 0 001.103-1.103v-.024a1.103 1.103 0 00-1.103-1.104H1.403z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});