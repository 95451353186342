define("ember-light-table/components/lt-spanned-row", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.visible}}
    <tr class="lt-row {{html-safe this.classes}}">
      <td colspan={{this.colspan}}>
        {{yield this.yield}}
      </td>
    </tr>
  {{/if}}
  
  */
  {
    "id": "AnqVYRU2",
    "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[10,\"tr\"],[15,0,[29,[\"lt-row \",[28,[37,1],[[30,0,[\"classes\"]]],null]]]],[12],[1,\"\\n    \"],[10,\"td\"],[15,\"colspan\",[30,0,[\"colspan\"]]],[12],[1,\"\\n      \"],[18,1,[[30,0,[\"yield\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"html-safe\",\"yield\"]]",
    "moduleName": "ember-light-table/components/lt-spanned-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    colspan: 1,
    tagName: '',
    visible: true
  }));
});