define("ember-svg-jar/inlined/all-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.692 18.154H7.385v2.154h4.307v-2.154zM18.154 18.154h-4.308v2.154h4.308v-2.154zM11.692 13.846H7.385V16h4.307v-2.154zM18.154 22.462h-4.308v2.153h4.308v-2.153zM24.615 13.846h-4.307V16h4.307v-2.154zM24.615 22.462h-4.307v2.153h4.307v-2.153z\"/><path d=\"M27.846 2H4.154A2.157 2.157 0 002 4.154v23.692A2.157 2.157 0 004.154 30h23.692A2.157 2.157 0 0030 27.846V4.154A2.157 2.157 0 0027.846 2zm0 2.154v4.308H4.154V4.154h23.692zM4.154 27.846v-17.23h23.692v17.23H4.154z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});