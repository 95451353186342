define("ember-svg-jar/inlined/download-dr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm1.389-21.833H26V8.5h-8.055l-2.889-2.167a1.677 1.677 0 00-1-.333h-6.39v1.667h6.39l3.333 2.5zM14.095 26h10.238A1.668 1.668 0 0026 24.333v-10a1.668 1.668 0 00-1.667-1.666h-8.055L13.389 10.5a1.676 1.676 0 00-1-.333H7.667A1.668 1.668 0 006 11.833v7.152a.947.947 0 01.476-.128h6.667c.526 0 .952.427.952.953V26zm-1.42-2.77l.288.289a.311.311 0 010 .441l-2.53 2.531a.311.311 0 01-.44 0l-2.532-2.53a.311.311 0 010-.442l.289-.29a.313.313 0 01.447.006l1.494 1.569v-3.742c0-.173.14-.313.313-.313h.417c.173 0 .312.14.312.313v3.742l1.495-1.569a.31.31 0 01.446-.005z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});