define("ember-svg-jar/inlined/font-awesome/bold-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.718 14.875a7.624 7.624 0 001.688-4.076C26.866 6.031 23.125 2 18.464 2H6a1 1 0 00-1 1v3a1 1 0 001 1h1.992v18H6a1 1 0 00-1 1v3a1 1 0 001 1h13.082c4.425 0 8.384-3.234 8.813-7.65.296-3.028-1.024-5.754-3.177-7.475zM12.978 7h5.486a3 3 0 110 6h-5.485V7zm5.486 18h-5.485v-7h5.485a3.5 3.5 0 110 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});