define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 2a14 14 0 100 28 14 14 0 000-28zm0 26a12 12 0 110-24 12 12 0 010 24z\"/><path d=\"M16 25a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 8h-1.5a4.49 4.49 0 00-4.5 4.5v.5h2v-.5a2.5 2.5 0 012.5-2.5H17a2.5 2.5 0 010 5h-2v4.5h2V17a4.5 4.5 0 100-9z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});