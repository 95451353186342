define("ember-svg-jar/inlined/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 19H6v-2a3.003 3.003 0 013-3h5v2H9a1.001 1.001 0 00-1 1v2zM12 13a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zM20 20a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zM26 26h-2v-2a1.001 1.001 0 00-1-1h-6a1.001 1.001 0 00-1 1v2h-2v-2a3.004 3.004 0 013-3h6a3.004 3.004 0 013 3v2z\"/><path d=\"M8 30H4a2.002 2.002 0 01-2-2V4a2.002 2.002 0 012-2h4v2H4v24h4v2zM28 30h-4v-2h4V4h-4V2h4a2.002 2.002 0 012 2v24a2.002 2.002 0 01-2 2z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});