define("ember-svg-jar/inlined/font-awesome/circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 .5C7.437.5.5 7.438.5 16c0 8.563 6.938 15.5 15.5 15.5 8.563 0 15.5-6.938 15.5-15.5C31.5 7.437 24.562.5 16 .5zm0 28C9.094 28.5 3.5 22.906 3.5 16S9.094 3.5 16 3.5 28.5 9.094 28.5 16 22.906 28.5 16 28.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});