define("ember-svg-jar/inlined/font-awesome/exclamation-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 27c0 2.757-2.243 5-5 5s-5-2.243-5-5 2.243-5 5-5 5 2.243 5 5zM11.579 1.575l.85 17A1.5 1.5 0 0013.927 20h4.146a1.5 1.5 0 001.498-1.425l.85-17A1.5 1.5 0 0018.923 0h-5.846a1.5 1.5 0 00-1.498 1.575z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});