define("ember-svg-jar/inlined/ibm/save-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 20l1.427 1.903L23 26.963l-4.427-5.06L20 20h6m1-2h-8l-3 4 7 8 7-8-3-4z\"/><path d=\"M16 26h-4v-8h2v-2h-2a2 2 0 00-2 2v8H6V6h4v4a2 2 0 002 2h8a2 2 0 002-2V6.41l4 4V16h2v-6a1 1 0 00-.29-.71l-5-5A.999.999 0 0022 4H6a2 2 0 00-2 2v20a2 2 0 002 2h10zM12 6h8v4h-8z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});