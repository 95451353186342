define("ember-svg-jar/inlined/formula-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.603 10.362c-.79.786-2.083.357-2.659-1.428 0 0-.933.57-1.113 4.713-.002.036 2.12 0 2.12 0l-.144 1.215h-2.3s-.287 3.928-1.437 6.072c-.44.82-1.508 1.785-3.161 2.07-.888.154-1.985-.06-2.576-.537-.51-.41-.39-1.34.06-1.748 1.582-1.429 2.588 1.5 2.588 1.5 1.077-.071 2.299-7.357 2.299-7.357h-2.012l.144-1.215h2.012s.244-1.656.503-2.428c.287-.857 1.077-2.143 1.868-2.571.791-.43 1.293-.572 2.658-.5 1.366.07 2.013 1.356 1.15 2.214zM21.184 17.96c-.037-2.125-2.38-2.563-3.787-1.113 0 0-.036.101.18.034 1.843-.575 2.093 2.427 2.093 2.427l-.578.808c-2.668-.943-2.956 2.023-1.226 1.82.73-.084 1.984-1.921 1.984-1.921.525 2.95 2.778 1.762 3.615 1.204.064-.042.02-.135-.055-.12-1.922.373-2.082-2.533-2.082-2.533s.431-.806.54-.776c2.273.64 3.124-1.786 1.479-1.786-1.154 0-2.163 1.955-2.163 1.955z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 3.7A2.7 2.7 0 013.7 1h24.6A2.7 2.7 0 0131 3.7v24.6a2.7 2.7 0 01-2.7 2.7H3.7A2.7 2.7 0 011 28.3V3.7zm2.4.6a.9.9 0 01.9-.9h23.4a.9.9 0 01.9.9v23.4a.9.9 0 01-.9.9H4.3a.9.9 0 01-.9-.9V4.3z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});