define("ember-svg-jar/inlined/data-ready", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 6.5h-4v-4a2.003 2.003 0 00-2-2H2a2.002 2.002 0 00-2 2v18a2.003 2.003 0 002 2h4v4a2.003 2.003 0 002 2h18a2.003 2.003 0 002-2v-18a2.003 2.003 0 00-2-2zm-6 14h-8v-5h8v5zm0-7h-8v-5h8v5zm-10 0H2v-5h8v5zm10-11v4H2v-4h18zm-18 18v-5h8v5H2zm24 6H8v-4h12a2.003 2.003 0 002-2v-12h4v18z\" fill=\"#161616\"/>",
    "attrs": {
      "width": "28",
      "height": "29",
      "viewBox": "0 0 28 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});