define("ember-svg-jar/inlined/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 21h-2v5h2v-5zm-4-5h-2v10h2V16zM11 26a5.006 5.006 0 01-5-5h2a3 3 0 103-3v-2a5 5 0 110 10z\"/><path d=\"M28 2H4a2.002 2.002 0 00-2 2v24a2.002 2.002 0 002 2h24a2.003 2.003 0 002-2V4a2.002 2.002 0 00-2-2zm0 9H14V4h14v7zM12 4v7H4V4h8zM4 28V13h24l.002 15H4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});