define("ember-svg-jar/inlined/font-awesome/list-ul-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 3a3 3 0 100 6 3 3 0 000-6zm0 10a3 3 0 100 6 3 3 0 000-6zm0 10a3 3 0 100 6 3 3 0 000-6zm28 1H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1v-2a1 1 0 00-1-1zm0-20H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1V5a1 1 0 00-1-1zm0 10H11a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});