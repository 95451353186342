define("ember-svg-jar/inlined/portfolio-aggregated-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.429 12.5h8.5v1.02h-7.293l4.5 4.595-4.5 4.593h7.293v1.021h-8.5v-1.232l4.293-4.382-4.293-4.383V12.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.286 2h8.194c.302 0 .6.06.879.179.278.118.531.291.744.51L16 6.666h13.714c.607 0 1.188.245 1.617.683.428.438.669 1.031.669 1.65v18.667c0 .618-.24 1.212-.67 1.65a2.262 2.262 0 01-1.616.683H2.286a2.262 2.262 0 01-1.617-.683A2.358 2.358 0 010 27.667V4.333c0-.619.24-1.212.67-1.65A2.262 2.262 0 012.285 2zm12.103 6.312L10.48 4.333H2.286v23.334h27.428V9H15.051l-.662-.688z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});