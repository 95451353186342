define("ember-svg-jar/inlined/wistia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M32.297 11.894s-.781 1.533-4.628 3.963c-1.65 1.041-5.063 2.14-9.431 2.546-2.372.231-6.712.058-8.592.058-1.88 0-2.748.405-4.426 2.285L-1 27.776h3.79c1.649 0 11.774.578 16.287-.666 14.638-3.992 13.22-15.216 13.22-15.216z\"/><path d=\"M31.892 7.468C32.586 3.158 30.185 2 30.185 2s.087 3.5-6.335 4.253C18.15 6.918-1 6.426-1 6.426l6.162 7.059c1.678 1.91 2.546 2.14 4.426 2.256 1.88.116 6.017.087 8.823-.144 3.067-.232 7.435-1.244 10.386-3.53 1.504-1.186 2.806-2.806 3.095-4.6z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});