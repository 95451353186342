define("ember-svg-jar/inlined/ibm/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 19h4v2h-4z\"/><path d=\"M6 2v26a2 2 0 002 2h16a2 2 0 002-2V2zm18 26H8V16h16zm0-14H8v-4h16zM8 8V4h16v4z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});