define("ember-scrollable/services/scrollbar-thickness", ["exports", "@ember/service", "ember-scrollable/util/measurements"], function (_exports, _service, _measurements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScrollbarThicknessService extends _service.default {
    constructor() {
      super(...arguments);
      this.thickness = this.calculateScrollbarThickness();
    }
    calculateScrollbarThickness() {
      let tempEl = document.createElement('div');
      tempEl.setAttribute('style', 'width: 50px; position: absolute; left: -100px;');
      tempEl.classList.add('scrollbar-width-tester');
      tempEl.innerHTML = `<div style="overflow: scroll;"><div class="scrollbar-width-tester__inner"></div></div>`;
      document.body.appendChild(tempEl);
      let width = (0, _measurements.getWidth)(tempEl);
      let widthMinusScrollbars = (0, _measurements.getWidth)(tempEl.querySelector('.scrollbar-width-tester__inner'));
      tempEl.remove();
      return width - widthMinusScrollbars;
    }
  }
  _exports.default = ScrollbarThicknessService;
});