define("ember-svg-jar/inlined/navigation/plus-in-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 2C8.28 2 2 8.281 2 16s6.28 14 14 14 14-6.281 14-14S23.72 2 16 2zm0 25.401c-6.288 0-11.403-5.114-11.403-11.4C4.597 9.71 9.712 4.597 16 4.597S27.403 9.712 27.403 16c0 6.287-5.115 11.401-11.403 11.401z\"/><path d=\"M22.426 14.81H17.19V9.575a1.19 1.19 0 10-2.38 0v5.235H9.574a1.19 1.19 0 100 2.38h5.236v5.236a1.19 1.19 0 102.38 0V17.19h5.235a1.19 1.19 0 100-2.38z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});