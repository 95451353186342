define("ember-svg-jar/inlined/activity-icon-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 32A16.018 16.018 0 010 16 16.018 16.018 0 0116 0a16.018 16.018 0 0116 16 16.018 16.018 0 01-16 16zm-2.898-15.029a4.192 4.192 0 00-4.165 4.186v1.293a1.483 1.483 0 00.437 1.059 1.49 1.49 0 001.058.44h10.971a1.502 1.502 0 001.493-1.494v-1.298a4.192 4.192 0 00-4.187-4.188h-.528a5.39 5.39 0 01-2.272.498 5.376 5.376 0 01-2.27-.498l-.537.002zM15.913 8a3.99 3.99 0 00-3.986 3.986 3.99 3.99 0 003.986 3.987 3.99 3.99 0 003.986-3.987A3.99 3.99 0 0015.913 8z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});