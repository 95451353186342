define("ember-svg-jar/inlined/vimeo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.4 3H5.6A2.603 2.603 0 003 5.6v20.8C3 27.834 4.167 29 5.6 29h20.8c1.433 0 2.6-1.167 2.6-2.6V5.6C29 4.167 27.834 3 26.4 3zm-1.52 8.636c-.082 1.828-1.359 4.335-3.83 7.51-2.555 3.32-4.72 4.979-6.484 4.979-1.096 0-2.02-1.01-2.78-3.035-1.48-5.415-2.112-8.59-3.33-8.59-.14 0-.633.296-1.475.883L6.1 12.245c2.165-1.903 4.23-4.016 5.525-4.132 1.463-.14 2.362.859 2.699 3 1.201 7.615 1.735 8.764 3.923 5.317.783-1.242 1.207-2.188 1.265-2.838.203-1.927-1.503-1.794-2.658-1.3.923-3.024 2.687-4.492 5.293-4.411 1.933.052 2.844 1.306 2.733 3.755z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});