define("ember-svg-jar/inlined/font-awesome/sort-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.9 22.4H4.1c-2.14 0-3.21-2.59-1.7-4.1L14.3 6.4a2.39 2.39 0 013.39 0l11.9 11.9c1.52 1.51.45 4.1-1.69 4.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 52"
    }
  };
});