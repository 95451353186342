define("ember-svg-jar/inlined/metric-alerts/log/compare-to-metric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zm-4.496-11.933H9.04v-7.676h2.464v7.676zm4.143 0h-2.464v-5.163h2.464v5.163zm1.643 0v-7.755h2.464v7.755H17.29zm4.107.029v-5.83h2.464v5.83h-2.464zm2.572.87h-15v.58h15v-.58z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});