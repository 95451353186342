define("ember-svg-jar/inlined/dashboard-templates/finstrat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z\" fill=\"#F2C0C6\"/><path d=\"M10.667 13.576h-1.94a.97.97 0 00-.97.97v7.757c0 .536.435.97.97.97h1.94a.97.97 0 00.97-.97v-7.758a.97.97 0 00-.97-.97zM16.485 8.727h-1.94a.97.97 0 00-.97.97v12.606c0 .536.435.97.97.97h1.94a.97.97 0 00.97-.97V9.697a.97.97 0 00-.97-.97z\" fill=\"#fff\"/><path d=\"M22.303 7.758h-1.94a.97.97 0 00-.97.97v13.575c0 .536.435.97.97.97h1.94a.97.97 0 00.97-.97V8.727a.97.97 0 00-.97-.97z\" fill=\"#ED475D\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});