define("ember-svg-jar/inlined/font-awesome/sort-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.1 28.8h23.8c2.14 0 3.21 2.59 1.7 4.1L17.7 44.8c-.94.94-2.46.94-3.39 0L2.4 32.9c-1.51-1.51-.44-4.1 1.7-4.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 52"
    }
  };
});