define("ember-svg-jar/inlined/activity-icon-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.229 20.496a4.098 4.098 0 114.098-4.098 4.114 4.114 0 01-4.098 4.098zm.002-6.549a1.791 1.791 0 001.497 2.752 1.77 1.77 0 00.962-.28 2.465 2.465 0 11-2.46-2.472z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm10.03-15.584c-2.32-2.674-5.53-5.749-9.801-5.749a9.077 9.077 0 00-4.965 1.527 26.515 26.515 0 00-4.82 4.16l-.05.05.342.355.01.01c2.818 2.884 5.252 5.375 9.485 5.375a9.76 9.76 0 005.545-1.865 27.11 27.11 0 004.012-3.525l.28-.28-.038-.058z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});