define("ember-svg-jar/inlined/activity-icon-react", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm8.651-14.988c.39-.433.601-.994.592-1.571a2.04 2.04 0 00-.61-1.425 2.114 2.114 0 00-1.447-.607H20.2a1.757 1.757 0 01.206-.478 8.572 8.572 0 00.405-.783c.084-.206.152-.419.203-.635.067-.263.102-.533.102-.804v-.413a4.472 4.472 0 00-.052-.475 2.644 2.644 0 00-.13-.53 2.458 2.458 0 00-.259-.476 1.498 1.498 0 00-.432-.429 2.383 2.383 0 00-.647-.276 3.274 3.274 0 00-.889-.11.673.673 0 00-.484.202 1.839 1.839 0 00-.366.53c-.087.176-.158.36-.21.55-.04.15-.085.365-.135.646a5.14 5.14 0 01-.146.64 3.34 3.34 0 01-.19.514c-.078.188-.191.36-.334.507-.385.404-.748.829-1.088 1.27-.352.45-.715.876-1.088 1.28-.373.404-.646.612-.818.624a.711.711 0 00-.457.224.637.637 0 00-.195.46v6.783a.624.624 0 00.206.471.719.719 0 00.485.207 7.991 7.991 0 011.702.464c.554.183.986.322 1.299.418.312.096.749.198 1.31.308a8.14 8.14 0 001.552.163h1.39a2.72 2.72 0 001.155-.18 2.68 2.68 0 00.968-.646 2.524 2.524 0 00.528-1.914 2.05 2.05 0 00.582-.994 2.137 2.137 0 000-1.237c.324-.415.486-.929.458-1.45a2.483 2.483 0 00-.181-.828zm-15.96-2.254h3.106v.01c.18.003.353.075.48.2a.68.68 0 01.205.473v6.771a.68.68 0 01-.204.472.706.706 0 01-.481.201H8.69a.675.675 0 01-.484-.202.65.65 0 01-.206-.476v-6.771a.64.64 0 01.206-.476.664.664 0 01.484-.202z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});