define("ember-svg-jar/inlined/request-one-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 30h6v-2H6V4h16v9h2V4a2.002 2.002 0 00-2-2H6a2.002 2.002 0 00-2 2v24a2.002 2.002 0 002 2z\"/><path d=\"M20 6H8v2h12V6zM8 10h12v2H8v-2zM8 14h5.333v2H8v-2zM8 24h4v2H8v-2zM21 30a7 7 0 117-7 7.008 7.008 0 01-7 7zm0-12.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z\"/><path d=\"M21.786 20h-1.143v.6H19.5v1.2h1.143v3H19.5V26h3.429v-1.2h-1.143V20z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});