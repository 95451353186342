define("ember-svg-jar/inlined/ibm/mail-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 23H4V7.91l11.43 7.91a1 1 0 001.14 0L28 7.91V17h2V7a2 2 0 00-2-2H4a2 2 0 00-2 2v16a2 2 0 002 2h10v-2zM25.8 7L16 13.78 6.2 7h19.6z\"/><path d=\"M28 22.5a5.5 5.5 0 10-5.5 5.5 5.506 5.506 0 005.5-5.5zm-1.833 0a3.623 3.623 0 01-.52 1.85l-4.998-4.997a3.624 3.624 0 011.851-.52 3.67 3.67 0 013.667 3.667zm-7.334 0a3.623 3.623 0 01.52-1.85l4.998 4.997a3.624 3.624 0 01-1.851.52 3.67 3.67 0 01-3.667-3.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});