define("ember-svg-jar/inlined/font-awesome/linkedin-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 2H3.994C2.894 2 2 2.906 2 4.019V27.98C2 29.094 2.894 30 3.994 30H28c1.1 0 2-.906 2-2.019V4.02C30 2.906 29.1 2 28 2zM10.462 26h-4.15V12.637h4.157V26h-.006zM8.387 10.812a2.407 2.407 0 010-4.812 2.411 2.411 0 012.407 2.406 2.403 2.403 0 01-2.407 2.406zM26.02 26h-4.15v-6.5c0-1.55-.032-3.544-2.157-3.544-2.162 0-2.493 1.688-2.493 3.431V26h-4.15V12.637h3.981v1.825h.056c.556-1.05 1.913-2.156 3.931-2.156 4.2 0 4.982 2.769 4.982 6.369V26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});