define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.79 19.086H20.65l-.427-.343c1.352-1.577 2.206-3.566 2.206-5.829C22.43 7.977 18.302 4 13.18 4 8.127 4 4 7.977 4 12.914c0 4.938 4.127 8.915 9.25 8.915a9.772 9.772 0 006.049-2.126l.426.343v1.097L26.841 28l2.135-2.057-7.187-6.857zm-8.54 0c-3.557 0-6.404-2.743-6.404-6.172 0-3.428 2.847-6.171 6.404-6.171 3.558 0 6.404 2.743 6.404 6.171 0 3.429-2.846 6.172-6.404 6.172z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});