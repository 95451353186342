define("ember-svg-jar/inlined/chart-types/funnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.6 36.7V44h14.8v-7.4\"/><path class=\"chart-type-color-fill\" d=\"M12.3 16l10.3 13.3v5.3h14.8v-5.3L47.7 16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  };
});