define("ember-svg-jar/inlined/chart-types/line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" class=\"chart-type-color-stroke\" d=\"M10.7 34.6l9.4-11.7 10 14.8L41 25.4l8.9-4.2\"/><path fill=\"none\" stroke=\"#000\" stroke-width=\"3\" stroke-linecap=\"square\" stroke-linejoin=\"bevel\" stroke-miterlimit=\"10\" d=\"M49.8 26.7L41 41.8 30.1 26.6l-10 6-9.2 8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 59.8 60"
    }
  };
});