define("ember-svg-jar/inlined/percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.966 9.966a4.667 4.667 0 10-6.6-6.6 4.667 4.667 0 006.6 6.6zm18.667 12.068a4.667 4.667 0 10-6.6 6.6 4.667 4.667 0 006.6-6.6zM28.49 5.158l-1.649-1.65a2.332 2.332 0 00-3.3 0L3.51 23.544a2.333 2.333 0 000 3.3l1.65 1.649a2.332 2.332 0 003.299 0L28.49 8.458a2.333 2.333 0 000-3.3z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});