define("ember-svg-jar/inlined/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.874 8.863H6.29l1.29-1.295-.705-.705-2.5 2.5 2.5 2.5.705-.705-1.29-1.295h6.585v-1z\" fill=\"currentColor\"/><path d=\"M10.874 6.363v-2a.5.5 0 00-.145-.355l-3.5-3.5a.5.5 0 00-.355-.145h-5a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1v-1h-1v1h-8v-12h4v3a1 1 0 001 1h3v1h1zm-4-2V1.568L9.67 4.363H6.874z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "13",
      "height": "15",
      "viewBox": "0 0 13 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});