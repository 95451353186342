define("ember-svg-jar/inlined/ibm/workspace-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 6v5H17V6h10zm0-2H17a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-2-2zM12 25H6v-8h6v-2H6a2 2 0 00-2 2v8a2 2 0 002 2h6v-2zm18-5H16.828l2.586-2.586L18 16l-5 5 5 5 1.414-1.414L16.828 22H30v-2zM11 6v5H6V6h5zm0-2H6a2 2 0 00-2 2v5a2 2 0 002 2h5a2 2 0 002-2V6a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});