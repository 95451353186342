define("ember-svg-jar/inlined/font-awesome/cog-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30.462 19.731L27.8 18.194a12.03 12.03 0 000-4.388l2.662-1.537a.755.755 0 00.344-.875 15.566 15.566 0 00-3.419-5.913.752.752 0 00-.925-.144L23.8 6.875A11.796 11.796 0 0020 4.68V1.612a.749.749 0 00-.587-.73 15.712 15.712 0 00-6.825 0 .749.749 0 00-.588.73v3.075a12.168 12.168 0 00-3.8 2.194L5.544 5.344a.743.743 0 00-.925.143A15.473 15.473 0 001.2 11.4c-.106.337.037.7.344.875l2.662 1.537a12.039 12.039 0 000 4.388l-2.662 1.537a.755.755 0 00-.344.875 15.566 15.566 0 003.419 5.913.752.752 0 00.925.144l2.662-1.538a11.796 11.796 0 003.8 2.194V30.4c0 .35.244.656.588.731 2.293.513 4.644.488 6.825 0a.749.749 0 00.587-.731v-3.075a12.167 12.167 0 003.8-2.194l2.663 1.538a.743.743 0 00.925-.144 15.472 15.472 0 003.419-5.913.772.772 0 00-.35-.88zM16 21c-2.756 0-5-2.244-5-5s2.244-5 5-5 5 2.244 5 5-2.244 5-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});