define("ember-svg-jar/inlined/generate-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 17.5v2h4.586L0 26.086 1.414 27.5 8 20.914V25.5h2v-8H2zM22 19.5h-4v2h4v-2zM18 15.5h-4v2h4v-2zM22 11.5h-4v2h4v-2zM10 11.5H6v2h4v-2z\" fill=\"#161616\"/><path d=\"M25 .5H3a2.003 2.003 0 00-2 2v11h2v-5h22v16H14v2h11a2.003 2.003 0 002-2v-22a2.003 2.003 0 00-2-2zm0 6H3v-4h22v4z\" fill=\"#161616\"/>",
    "attrs": {
      "width": "27",
      "height": "28",
      "viewBox": "0 0 27 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});