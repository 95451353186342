define("ember-data-model-fragments/attributes/fragment", ["exports", "@ember/debug", "@ember/object", "@ember/utils", "@ember-data/store/-private", "ember-data-model-fragments/fragment", "ember-data-model-fragments/util/meta-type-for", "ember-data-model-fragments/util/instance-of-type"], function (_exports, _debug, _object, _utils, _private, _fragment, _metaTypeFor, _instanceOfType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fragment;
  /**
   `MF.fragment` defines an attribute on a `DS.Model` or `MF.Fragment`. Much
   like `DS.belongsTo`, it creates a property that returns a single fragment of
   the given type.
  
   It takes an optional hash as a second parameter, currently supported options
   are:
  
   - `defaultValue`: An object literal or a function to be called to set the
   attribute to a default value if none is supplied. Values are deep copied
   before being used. Note that default values will be passed through the
   fragment's serializer when creating the fragment. Defaults to `null`.
   - `polymorphic`: Whether or not the fragments in the array can be child
   classes of the given type.
   - `typeKey`: If `polymorphic` is true, the property to use as the fragment
   type in the normalized data. Defaults to `type`.
  
   Example
  
   ```javascript
   App.Person = DS.Model.extend({
      name: MF.fragment('name', { defaultValue: {} })
    });
  
   App.Name = MF.Fragment.extend({
      first: DS.attr('string'),
      last: DS.attr('string')
    });
   ```
  
   @namespace MF
   @method fragment
   @param {String} type the fragment type
   @param {Object} options a hash of options
   @return {Attribute}
   */
  function fragment(type, options) {
    options = options || {};
    const metaType = (0, _metaTypeFor.default)('fragment', type, options);
    const meta = {
      modelName: type,
      type: metaType,
      isAttribute: true,
      isFragment: true,
      kind: 'fragment',
      options
    };

    // eslint-disable-next-line ember/require-computed-property-dependencies
    return (0, _object.computed)({
      get(key) {
        const recordData = (0, _private.recordDataFor)(this);
        const fragment = recordData.getFragment(key);
        if (fragment === null) {
          return null;
        }
        return fragment._fragmentGetRecord();
      },
      set(key, value) {
        (false && !(value === null || (0, _fragment.isFragment)(value) || (0, _utils.typeOf)(value) === 'object') && (0, _debug.assert)('You must pass a fragment or null to set a fragment', value === null || (0, _fragment.isFragment)(value) || (0, _utils.typeOf)(value) === 'object'));
        const recordData = (0, _private.recordDataFor)(this);
        if (value === null) {
          recordData.setDirtyFragment(key, null);
          return null;
        }
        if ((0, _fragment.isFragment)(value)) {
          (false && !((0, _instanceOfType.default)(this.store.modelFor(type), value)) && (0, _debug.assert)(`You can only set '${type}' fragments to this property`, (0, _instanceOfType.default)(this.store.modelFor(type), value)));
          (0, _fragment.setFragmentOwner)(value, recordData, key);
          recordData.setDirtyFragment(key, (0, _private.recordDataFor)(value));
          return value;
        }
        const fragmentRecordData = recordData.getFragment(key);
        const actualType = (0, _fragment.getActualFragmentType)(type, options, value, this);
        if ((fragmentRecordData === null || fragmentRecordData === void 0 ? void 0 : fragmentRecordData.modelName) !== actualType) {
          const fragment = this.store.createFragment(actualType, value);
          (0, _fragment.setFragmentOwner)(fragment, recordData, key);
          recordData.setDirtyFragment(key, (0, _private.recordDataFor)(fragment));
          return fragment;
        }
        const fragment = fragmentRecordData._fragmentGetRecord();
        fragment.setProperties(value);
        return fragment;
      }
    }).meta(meta);
  }
});