define("ember-svg-jar/inlined/setup-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.285 10.72V9.095c0-.102.042-.195.124-.282a.377.377 0 01.27-.126H21.89a.38.38 0 01.273.128.39.39 0 01.121.278v1.624a.401.401 0 01-.123.282.379.379 0 01-.27.126H11.676a.365.365 0 01-.268-.128.39.39 0 01-.124-.277zm.394 4.467a.365.365 0 01-.272-.128.39.39 0 01-.122-.277v-1.625c0-.102.042-.195.124-.281a.376.376 0 01.27-.126H21.89a.38.38 0 01.273.128.391.391 0 01.121.277v1.625a.402.402 0 01-.123.282.379.379 0 01-.27.125H11.678zm14.68 8.125c-.099.303-.147.845-.147 1.625s.048 1.322.147 1.625a.712.712 0 01.467.28c.114.152.175.34.172.533v.812a.807.807 0 01-.22.585.763.763 0 01-.566.228H8.93c-1.081 0-2.005-.4-2.774-1.192a3.919 3.919 0 01-.866-1.313A4.028 4.028 0 015 24.935V7.06c0-1.118.386-2.073 1.153-2.868C6.926 3.399 7.85 3 8.93 3h17.283c.23 0 .42.076.566.228.147.151.22.346.22.584V22.5a.864.864 0 01-.172.533.717.717 0 01-.47.28zm-2.011 0H8.928c-.206-.002-.41.04-.6.123-.19.083-.361.206-.504.36-.149.147-.267.325-.348.521-.08.197-.12.408-.119.621-.001.213.04.425.12.621.08.196.198.374.347.52.143.155.314.278.504.36.19.084.394.126.6.124h15.42a11.303 11.303 0 01-.1-1.625c0-.643.033-1.185.098-1.625h.002zm.293-17.875H8.929c-.207-.001-.411.04-.6.124-.19.083-.362.205-.505.36-.149.147-.267.324-.348.52-.08.197-.12.408-.119.621v14.17a3.547 3.547 0 011.572-.357H24.64V5.437z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});