define("ember-svg-jar/inlined/ibm/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 22H2v2h28v-2zm0 4H2v2h28v-2zm-6-16a2 2 0 100 4 2 2 0 000-4zm-8 6a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zm-8 0a2 2 0 100 4 2 2 0 000-4z\"/><path d=\"M28 20H4a2.005 2.005 0 01-2-2V6a2.005 2.005 0 012-2h24a2.005 2.005 0 012 2v12a2.003 2.003 0 01-2 2zm0-14H4v12h24V6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});