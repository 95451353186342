define("ember-svg-jar/inlined/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 3H5a2 2 0 00-2 2v22a2 2 0 002 2h22a2 2 0 002-2V5a2 2 0 00-2-2zm0 2v4H5V5h22zm-10 6h10v7H17v-7zm-2 7H5v-7h10v7zM5 20h10v7H5v-7zm12 7v-7h10v7H17z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});