define("ember-svg-jar/inlined/portfolio-metric-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_12_404)\"><path d=\"M30 3.914L28.586 2.5 15.293 15.793a1 1 0 001.414 1.414l4.18-4.18A5.995 5.995 0 1116 10.5v-2a8.011 8.011 0 106.316 3.098l2.847-2.847A11.88 11.88 0 0128 16.5a12 12 0 11-12-12v-2a14 14 0 1014 14 13.857 13.857 0 00-3.422-9.164L30 3.914z\"/></g><defs><clipPath id=\"clip0_12_404\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h32v32H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "32",
      "height": "33",
      "viewBox": "0 0 32 33",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});