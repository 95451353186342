define("ember-svg-jar/inlined/ibm/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 16.76v-1.53l1.92-1.68A2 2 0 0029.3 11l-2.36-4a2 2 0 00-1.73-1 2 2 0 00-.64.1l-2.43.82c-.42-.279-.857-.53-1.31-.75l-.51-2.52a2 2 0 00-2-1.61h-4.68a2 2 0 00-2 1.61l-.51 2.52c-.456.22-.897.471-1.32.75l-2.38-.86A2 2 0 006.79 6a2 2 0 00-1.73 1L2.7 11a2 2 0 00.41 2.51L5 15.24v1.53l-1.89 1.68A2 2 0 002.7 21l2.36 4a2 2 0 001.73 1c.217.002.433-.032.64-.1l2.43-.82c.42.279.857.53 1.31.75l.51 2.52a2 2 0 002 1.61h4.72a2 2 0 002-1.61l.51-2.52c.456-.22.897-.471 1.32-.75l2.42.82c.207.068.423.102.64.1a2 2 0 001.73-1l2.28-4a2 2 0 00-.41-2.51L27 16.76zM25.21 24l-3.43-1.16a8.862 8.862 0 01-2.71 1.57L18.36 28h-4.72l-.71-3.55a9.363 9.363 0 01-2.7-1.57L6.79 24l-2.36-4 2.72-2.4a8.9 8.9 0 010-3.13L4.43 12l2.36-4 3.43 1.16a8.86 8.86 0 012.71-1.57L13.64 4h4.72l.71 3.55a9.36 9.36 0 012.7 1.57L25.21 8l2.36 4-2.72 2.4a8.9 8.9 0 010 3.13L27.57 20l-2.36 4z\"/><path d=\"M16 22a6 6 0 116-6 5.94 5.94 0 01-6 6zm0-10a3.91 3.91 0 00-4 4 3.91 3.91 0 004 4 3.912 3.912 0 004-4 3.911 3.911 0 00-4-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});