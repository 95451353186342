define("ember-svg-jar/inlined/portfolio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28 10h-6V6a2 2 0 00-2-2h-8a2 2 0 00-2 2v4H4a2 2 0 00-2 2v14a2 2 0 002 2h24a2 2 0 002-2V12a2 2 0 00-2-2zM12 6h8v4h-8V6zM4 26V12h24v14H4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});