define("ember-svg-jar/inlined/activity-icon-decks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15zm6.546-23.818h-5.819V5.727h-1.454v1.455H9.455A1.455 1.455 0 008 8.636v10.182a1.454 1.454 0 001.455 1.455h5.818v4.363h-2.91v2.455h7.273v-2.455h-2.909v-4.363h5.819A1.455 1.455 0 0024 18.818V8.636a1.454 1.454 0 00-1.454-1.454z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});