define("ember-svg-jar/inlined/website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 2a14 14 0 100 28 14 14 0 000-28zm12 13h-6a24.26 24.26 0 00-2.79-10.55A12 12 0 0128 15zM16 28a4.993 4.993 0 01-.67 0A21.85 21.85 0 0112 17h8a21.85 21.85 0 01-3.3 11 4.986 4.986 0 01-.7 0zm-4-13a21.85 21.85 0 013.3-11 6 6 0 011.34 0A21.85 21.85 0 0120 15h-8zm.76-10.55A24.26 24.26 0 0010 15H4a12 12 0 018.79-10.55h-.03zM4.05 17h6a24.26 24.26 0 002.75 10.55A12 12 0 014.05 17zm15.16 10.55A24.26 24.26 0 0022 17h6a12 12 0 01-8.79 10.55z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});