define("ember-svg-jar/inlined/trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 7h-2V6a2.002 2.002 0 00-2-2H10a2.002 2.002 0 00-2 2v1H6a2.002 2.002 0 00-2 2v3a4.004 4.004 0 004 4h.322A8.17 8.17 0 0015 21.934V26h-5v2h12v-2h-5v-4.069A7.966 7.966 0 0023.74 16H24a4.004 4.004 0 004-4V9a2.002 2.002 0 00-2-2zM8 14a2.002 2.002 0 01-2-2V9h2v5zm14 0a6 6 0 01-6.185 5.997A6.2 6.2 0 0110 13.707V6h12v8zm4-2a2.002 2.002 0 01-2 2V9h2v3z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});