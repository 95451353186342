define("ember-svg-jar/inlined/font-awesome/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30.837 1.538l-6.5-1.5a1.51 1.51 0 00-1.718.868l-3 7a1.496 1.496 0 00.43 1.75l3.788 3.1a23.163 23.163 0 01-11.075 11.075l-3.1-3.787a1.498 1.498 0 00-1.75-.431l-7 3a1.518 1.518 0 00-.875 1.724l1.5 6.5A1.5 1.5 0 003 32c16.006 0 29-12.969 29-29 0-.7-.481-1.306-1.163-1.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});