define("ember-svg-jar/inlined/ibm/document-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 21h13.17l-2.58 2.59L25 25l5-5-5-5-1.41 1.41L26.17 19H13v2z\"/><path d=\"M22 14v-4a1 1 0 00-.29-.71l-7-7A1 1 0 0014 2H4a2 2 0 00-2 2v24a2 2 0 002 2h16a2 2 0 002-2v-2h-2v2H4V4h8v6a2 2 0 002 2h6v2zm-8-4V4.41L19.59 10z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});