define("ember-svg-jar/inlined/ibm/stacked-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 28H4a2 2 0 01-2-2V11a2 2 0 012-2h11a2 2 0 012 2v15a2 2 0 01-2 2zM4 11v15h11V11z\"/><path d=\"M26 6l-1.41 1.41L27 10h-5V6a2 2 0 00-2-2H10v2h10v14h2v-8h5l-2.41 2.59L26 16l5-5z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});