define("ember-svg-jar/inlined/ibm/document-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26 30H11a2.002 2.002 0 01-2-2v-6h2v6h15V6h-9V4h9a2.002 2.002 0 012 2v22a2.002 2.002 0 01-2 2z\"/><path d=\"M17 10h7v2h-7zm-1 5h8v2h-8zm-1 5h9v2h-9zm-6-1a5.005 5.005 0 01-5-5V3h2v11a3 3 0 006 0V5a1 1 0 00-2 0v10H8V5a3 3 0 016 0v9a5.005 5.005 0 01-5 5z\"/><path d=\"M0 0h32v32H0z\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});