define("ember-svg-jar/inlined/file-keynote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.123 13.5h12.545v1.673H20.65v5.92h-4.314V22.7a1.045 1.045 0 11-1.673 0v-1.607H9.999v-5.92h-.875V13.5zm2.547 6.273h7.307v-4.6H11.67v4.6z\" clip-rule=\"evenodd\"/><path d=\"M26.233 10.561L18.44 2.767C18.056 2.256 17.417 2 16.778 2H6.556A2.563 2.563 0 004 4.556v23a2.563 2.563 0 002.556 2.555h17.888A2.563 2.563 0 0027 27.556V12.35c0-.639-.256-1.278-.767-1.789zM6.556 27.556v-23h10.222l7.666 7.666v15.334H6.556z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});