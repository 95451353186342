define("ember-svg-jar/inlined/light-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 .875L9.125 0 5 4.125.875 0 0 .875 4.125 5 0 9.125.875 10 5 5.875 9.125 10 10 9.125 5.875 5 10 .875z\" fill=\"#991B1B\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});