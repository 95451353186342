define("ember-svg-jar/inlined/horizontal-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 3h28.72v25.13H2V3z\" fill=\"#F0F0F2\"/><path d=\"M30.72 15.565H2\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"square\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});